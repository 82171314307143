import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  authLocalStorage,
  sessionShopHash,
  sessionToken,
} from "../../../../authServices/jwt";
import Loading from "../../../../components/UI/Loading";
import { categories } from "../../../../configs/data/categories";
import { StorageKeys } from "../../../../constant/storageKeys";
import { GetAuthStateContext } from "../../../../utility/context/authContext";
import { GetShopStateContext } from "../../../../utility/context/shopContext";
import { capitalizing, toggleSidebarMobile } from "../../../../utility/helpers";
import {
  SubCategory,
  getFilterSubmenuByRole,
} from "../../../../utility/role/getFilterSubmenuByRole";
import { getUserVisitPermission } from "../../../../utility/role/getUserVisistPermission";
import { getLogoutSuccess } from "../../../../utility/store/action/authAction/authAction";
import { SidebarContainer } from "./sidebar.styles";
import { useIntegrationContext } from "../../../../utility/store/context/IntegrationContext/useIntegrationContext";
import { ConfigurationIcon } from "../../../../assets/icon/ConfigurationIcon";

interface IProps {
  showModalLogin: () => void;
}

function Sidebar({ showModalLogin }: IProps) {
  const [collapsed, setCollapsed] = useState(false);
  const [category, setCategory] = useState(categories);
  const history = useHistory();
  const { dispatch, authState } = GetAuthStateContext();
  const { loading, activatedIntegrations} = useIntegrationContext();

  const pathname = history.location.pathname
    .split("/")
    .filter((item) => item !== "");
  const [openKey, setOpenKey] = useState([`${pathname[0]}`]);
  const [selectKey, setSelectKey] = useState([
    pathname[0] === "app-configurations" ||
    pathname[0] === "reporting" ||
    pathname[0] === "notifications"
      ? pathname[0] + "/" + pathname[1]
      : pathname[0],
  ]);

  const rootKey = categories.map((cate) => cate.key);

  const { integrationResponse } = GetShopStateContext();

  // useEffect(() => {
  //   if (integrationResponse.activate) {
  //     setCategory(
  //       categories.filter((item: any) => {
  //         if (item.key === "notifications") {
  //           item.items = item?.items.filter((e: any) => e?.key !== "wishlist");
  //           return null;
  //         } else return item;
  //       })
  //     );
  //   }
  // }, [integrationResponse]);

  useEffect(() => {
    if (loading) return;

    if (!(activatedIntegrations?.some(item => item.active && item.notificationService !== "SEND_GRID"))) {
      const newCategories = [...categories];
      newCategories.splice(3, 0, {
        id: "9",
        key: "notifications",
        icon: <ConfigurationIcon />,
        items: [{ key: "wishlist" }, { key: "quotes" }],
        label: "app configuration",
      });

      setCategory(newCategories);
    }

    return () => {
      setCategory(categories);
    }

  }, [loading, activatedIntegrations]);

  const setDefaultSelectedKey = () => {
    const pathname = history.location.pathname
      .split("/")
      .filter((item) => item !== "");
    setSelectKey([pathname[1] ? pathname[0] + "/" + pathname[1] : pathname[0]]);
  };

  const onClickDashboard = (e: any) => {
    // Open document link
    if (e.key === "documentation") {
      window.open("https://thewishlistco.stoplight.io/", "_blank");
      return;
    }
    if (e.key === "logout") {
      authLocalStorage.clearTokens();
      // window.localStorage.removeItem(StorageKeys.KeycloackUser);
      // window.localStorage.removeItem(StorageKeys.Tenant);
      window.localStorage.clear();
      sessionShopHash.clearShopHash();
      authLocalStorage.clearSessionAppInside();
      dispatch(getLogoutSuccess());
      history.push("/auth");

      return;
    }
    history.push("/" + e.key);
    toggleSidebarMobile();
    setDefaultSelectedKey();
  };

  const onOpenChange = (keys: any[]) => {
    const latestOpenKey = keys.find((key) => openKey.indexOf(key) === -1);
    const _sessionHash = sessionToken.getToken();
    if (latestOpenKey === "app-configurations" && !_sessionHash) {
      showModalLogin();
      return;
    }
    if (latestOpenKey && rootKey.indexOf(latestOpenKey) === -1) {
      setOpenKey(keys);
    } else {
      setOpenKey(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const renderSidebar = () => {
    const filterPermissionCategories = category.filter((item) => {
      if (!authState.auth || !authState.auth.status || !item.key) return false;

      item.items = getFilterSubmenuByRole(
        authState.auth.status,
        item.items as SubCategory[]
      ) as any;

      return getUserVisitPermission(authState.auth.status, item.key);
    });

    return filterPermissionCategories.map((cate) => {
      const { key, icon, items } = cate;

      if (!items || items.length === 0) {
        return (
          <Menu.Item key={`${key}`} icon={icon}>
            {capitalizing(key || "")}
          </Menu.Item>
        );
      }
      const menuItem = items.map((item: any) => (
        // <Tooltip title="dffd" >

        <Menu.Item title={item?.tooltip} key={`${key}/${item.key}`}>
          {item?.label || capitalizing(item.key)}
        </Menu.Item>
        //  </Tooltip>
      ));

      return (
        <Menu.SubMenu key={key} title={capitalizing(key || "")} icon={icon}>
          {menuItem}
        </Menu.SubMenu>
      );
    });
  };

  return (
    <>
      <SidebarContainer
        className="sidebar menu-custom"
        collapsible
        collapsed={collapsed}
        onCollapse={() => {
          setCollapsed((prevState) => !prevState);
        }}
        theme="light"
        width="240px"
      >
        <Menu
          theme="light"
          defaultSelectedKeys={[
            pathname[1] ? pathname[0] + "/" + pathname[1] : pathname[0],
          ]}
          selectedKeys={selectKey}
          openKeys={openKey}
          onOpenChange={onOpenChange}
          mode="inline"
          onClick={onClickDashboard}
          style={{ position: "sticky", top: "10px" }}
        >
          {renderSidebar()}
        </Menu>
      </SidebarContainer>
      {authState.pending && <Loading />}
    </>
  );
}

export default Sidebar;
