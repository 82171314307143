import { CategoryKeys } from "../data/category.enum";
import { Roles } from "./roles.enum";

export const RolePermissions = {
  [Roles.TwcReportsUser]: [
    CategoryKeys.DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcStoreOwner]: [
    CategoryKeys.DASHBOARD,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.USER_ADMINISTRATION,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.APP_CONFIGURATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcGroupOwner]: [
    CategoryKeys.DASHBOARD,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.USER_ADMINISTRATION,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.APP_CONFIGURATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcStoreUser]: [
    CategoryKeys.DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.USER_ADMINISTRATION,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.LOGOUT,
  ],
  [Roles.StoreOwner]: [...Object.values(CategoryKeys)],
  [Roles.TwcSuperUser]: [...Object.values(CategoryKeys)],
  [Roles.OldAuthMethod]: [
    CategoryKeys.DASHBOARD,
    CategoryKeys.SUBSCRIPTION,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.POS_CONFIG,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.LOGOUT,
    CategoryKeys.USER_ADMINISTRATION,
  ],
};
