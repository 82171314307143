import {IActionType} from '../../interface/commonInterface'
import {
    IInitEmailActiveState,
    IInitEmailCreateState, IInitEmailDeleteState,
    IInitEmailEditState, IInitEmailExportState,
    IInitEmailListState, IInitEmailSingleState,
} from '../../interface/stateInitial'
import {
    ActiveEmailFailed,
    ActiveEmailPending, ActiveEmailReset, ActiveEmailSuccess,
    CreateEmailFailed,
    CreateEmailPending,
    CreateEmailSuccess,
    DeleteEmailFailed,
    DeleteEmailPending, DeleteEmailReset,
    DeleteEmailSuccess,
    EditEmailFailed,
    EditEmailPending,
    EditEmailSuccess, GetDefaultEmailListFailed, GetDefaultEmailListPending, GetDefaultEmailListSuccess,
    GetEmailByIdFailed,
    GetEmailByIdPending,
    GetEmailByIdSuccess,
    GetEmailListFailed,
    GetEmailListPending,
    GetEmailListSuccess, GetEmailThumbnailFailed, GetEmailThumbnailPending, GetEmailThumbnailSuccess,
} from '../action/emailAction/emailActionType'


export const initDefaultEmailListState: IInitEmailListState = {
    pending: false,
    errorMsg: '',
    result: [],
    pagination: {
        current: 1,
        defaultPageSize: 10,
        pageSize: 999999999,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true
    }
}

//get emails
export function defaultEmailListReducer(state = initDefaultEmailListState, action: IActionType): IInitEmailListState {
    const {type, payload} = action
    switch (type) {
        //get list email
        case GetDefaultEmailListPending:
            return {
                ...state,
                pending: true,
                pagination: payload || state.pagination
            }
        case GetDefaultEmailListFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload
            }
        case GetDefaultEmailListSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
                result: payload?.emails,
                pagination: {
                    ...state.pagination,
                    total: payload.total
                }
            }
        default:
            return {...state}
    }
}


export const initEmailListState: IInitEmailListState = {
    pending: false,
    errorMsg: '',
    result: [],
    pagination: {
        current: 1,
        defaultPageSize: 10,
        pageSize: 99999999999,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true
    }
}

//get emails
export function emailListReducer(state = initEmailListState, action: IActionType): IInitEmailListState {
    const {type, payload} = action
    switch (type) {
        //get list email
        case GetEmailListPending:
            return {
                ...state,
                pending: true,
                pagination: payload || state.pagination
            }
        case GetEmailListFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload
            }
        case GetEmailListSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
                result: payload?.emails,
                pagination: {
                    ...state.pagination,
                    total: payload.total
                }
            }
        default:
            return {...state}
    }
}

//create email
export const initEmailCreateState: IInitEmailCreateState = {
    pending: false,
    errorMsg: '',
    emailId: -1,
}

export const emailCreateReducer = (state = initEmailCreateState, action: IActionType): IInitEmailCreateState => {
    const {type, payload} = action
    switch (type) {
        // create email
        case CreateEmailPending:
            return {
                ...state,
                pending: true,
            }
        case CreateEmailFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload,
            }
        case CreateEmailSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
                emailId: payload?.id
            }
        default:
            return {...state}
    }
}

export const initEmailEditState: IInitEmailEditState = {
    pending: false,
    errorMsg: ''
}

//edit email
export const emailEditReducer = (state = initEmailEditState, action: IActionType): IInitEmailEditState => {
    const {type, payload} = action
    switch (type) {
        // edit email
        case EditEmailPending:
            return {
                ...state,
                pending: true
            }
        case EditEmailFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload
            }
        case EditEmailSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
            }
        default:
            return {...state}
    }
}

//get single email
export const initEmailSingleState: IInitEmailSingleState = {
    pending: false,
    errorMsg: '',
    getEmail: null
}

export const emailSingleReducer = (state = initEmailSingleState, action: IActionType): IInitEmailSingleState => {
    const {type, payload} = action
    switch (type) {
        // find one email
        case GetEmailByIdPending:
            return {
                ...state,
                errorMsg: '',
                pending: true
            }
        case GetEmailByIdSuccess:
            return {
                ...state,
                errorMsg: '',
                getEmail: payload,
                pending: false
            }
        case GetEmailByIdFailed:
            return {
                ...state,
                errorMsg: payload,
                pending: false,
                getEmail: null
            }
        default:
            return {...state}
    }
}

// delete email

export const initEmailDeleteState: IInitEmailDeleteState = {
    pending: false,
    errorMsg: '',
    emailId: -1
}

export const emailDeleteReducer = (state = initEmailDeleteState, action: IActionType): IInitEmailDeleteState => {
    const {type, payload} = action
    switch (type) {
        case DeleteEmailPending:
            return {
                ...state,
                pending: true,
                errorMsg: ''
            }
        case DeleteEmailFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload
            }
        case DeleteEmailSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
                emailId: payload
            }
        case DeleteEmailReset:
            return {
                ...state,
                pending: false,
                errorMsg: '',
                emailId: -1
            }
        default:
            return {...state}
    }
}


// export email design

export const initEmailExportState: IInitEmailExportState = {
    pending: false,
    errorMsg: '',
    thumbnail: ''
}

export const emailExportReducer = (state = initEmailExportState, action: IActionType): IInitEmailExportState => {
    const {type, payload} = action
    switch (type) {
        case GetEmailThumbnailPending:
            return {
                ...state,
                pending: true,
                errorMsg: ''
            }
        case GetEmailThumbnailFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload
            }
        case GetEmailThumbnailSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
                thumbnail: payload
            }
        default:
            return {...state}
    }
}

// active email

export const initEmailActiveState: IInitEmailActiveState = {
    pending: false,
    errorMsg: '',
    emailId: -1
}

export const emailActiveReducer = (state = initEmailActiveState, action: IActionType): IInitEmailActiveState => {
    const {type, payload} = action
    switch (type) {
        case ActiveEmailPending:
            return {
                ...state,
                pending: true,
                errorMsg: ''
            }
        case ActiveEmailFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload,
            }
        case ActiveEmailSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
                emailId: payload
            }
        case ActiveEmailReset:
            return {
                ...state,
                pending: false,
                errorMsg: '',
                emailId: -1
            }
        default:
            return {...state}
    }
}

