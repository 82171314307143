import {AxiosRequestConfig} from "axios";
import {
    axiosInstance,
    axiosInstanceLogin,
} from "../../../configs/axiosConfig";
import {
    ILoginEffect, IRegisterEffect,
    IResetPasswordEffect,
    IResetTokenEffect,
} from "./interface";

const configs: AxiosRequestConfig = {
    headers: {
        // 'Content-Type': 'application/json'
    },
};

export const getAuthEffect = async (shopHash: string) => {
    try {
        if (shopHash) {
            return await axiosInstance.get("/auth/me?shop_hash=" + shopHash);
        } else {
            return await axiosInstance.get("/auth/me");
        }
    } catch (error : any) {
        return error
    }
};

export const getLoginEffect = async (data: ILoginEffect) => {
    try {
        return await axiosInstanceLogin.post("/auth/login", data, configs);
    } catch (error : any) {
        return error
    }
};

export const getResetTokenEffect = async (data: IResetTokenEffect) => {
    try {
        return await axiosInstance.post(
            "/auth/forgot-password/send-code",
            data,
            configs
        );
    } catch (error : any) {
        return error
    }
};

export const resetPasswordEffect = async (data: IResetPasswordEffect) => {
    try {
        return await axiosInstance.post(
            "/auth/forgot-password/new-password",
            data
        );
    } catch (error : any) {
        return error
    }
};

export const getLogoutEffect = async () => {
    try {
        return await axiosInstance.post("/auth/logout", {}, configs);
    } catch (error : any) {
        return error
    }
};

export const refreshTokenEffect = async () => {
    try {
        return await axiosInstance.post("/auth/refresh-token", {}, configs);
    } catch (error : any) {
        return error;
    }
};

export const getResetPasswordCodeEffect = async (email: string) => {
    try {
        return await axiosInstance.post("/auth/forgot-password/send-code", {email});
    } catch (error : any) {
        return error;
    }
};

export const confirmResetPasswordCodeEffect = async (token: string) => {
    try {
        return await axiosInstance.post("/auth/forgot-password/confirm-code", {token});
    } catch (error : any) {
        return error;
    }
};

export const getRegisterEffect = async (data: IRegisterEffect) => {
    try {
        return await axiosInstanceLogin.post("/register/account", data);
    } catch (error : any) {
        return error;
    }
};



