import { Dispatch } from "react";
import {
  IActionType,
  IResultMiddleware,
} from "../../interface/commonInterface";
import { IShop } from "../../interface/model/shop";
import {
  cancelShopPlanFailed,
  cancelShopPlanPending,
  cancelShopPlanSuccess,
  getShopByIdFailed,
  getShopByIdPending,
  getShopByIdSuccess,
  getShopListFailed,
  getShopListPending,
  getShopListSuccess,
  getShopTimesLeftFailed,
  getShopTimesLeftPending,
  getShopTimesLeftSuccess,
} from "../action/shopAction/shopAction";
import { IPaginationEffect } from "../effect/interface";
import {
  cancelShopPlanEffect,
  getShopByIdEffect,
  getShopListEffect,
  getShopTimeEffect,
} from "../effect/shopEffect";

//handle state while get an Shop
export const getShopById = async (
  dispatch: Dispatch<IActionType>,
  shopHash: string
): Promise<IResultMiddleware> => {
  dispatch(getShopByIdPending());

  try {
    if (!shopHash) {
      throw new Error("Shop hash is empty");
    }

    const res: any = await getShopByIdEffect(shopHash);

    const shop = res.data?.data as IShop;

    if (shop && typeof shop === "object" && Object.keys(shop).length) {
      dispatch(getShopByIdSuccess(shop));
      return {
        result: true,
      };
    }

    return {
      result: true,
    };
  } catch (e) {
    dispatch(getShopByIdFailed("Cannot get Shop information"));
    console.log("Cannot get shop information", e);
    return {
      result: false,
    };
  }
};

export const cancelShopPlan = async (
  dispatch: Dispatch<IActionType>,
  { plan_id, shop_hash }: any
): Promise<IResultMiddleware> => {
  dispatch(cancelShopPlanPending());
  const res: any = await cancelShopPlanEffect({ plan_id, shop_hash });
  if (res && res.status >= 200 && res.status <= 300) {
    dispatch(cancelShopPlanSuccess());
    return {
      result: true,
      message: "Cancel Plan Successfully",
    };
  } else {
    dispatch(cancelShopPlanFailed());
    return {
      result: false,
      message: res?.data?.message || "Cannot cancel plan",
    };
  }
};

export const getShopList = async (
  dispatch: Dispatch<IActionType>,
  data: IPaginationEffect
): Promise<IResultMiddleware> => {
  dispatch(getShopListPending(data.pagination));
  const res: any = await getShopListEffect(data);
  if (res && res.status >= 200 && res.status <= 300) {
    const Shops = res.data?.data?.Shops;
    const total = res.data?.data?.total;
    if (Shops) {
      dispatch(getShopListSuccess(Shops, total));
    } //if current page have a row
    else if (data.pagination.current && data.pagination.current > 1) {
      getShopList(dispatch, {
        ...data,
        pagination: {
          ...data.pagination,
          current: --data.pagination.current,
        },
      });
    } else {
      dispatch(getShopListSuccess([], 0));
    }
    return {
      result: true,
      message: "Get Shops Successfully",
    };
  } else {
    dispatch(getShopListFailed(res?.data?.message || "Some thing wrong"));
    return {
      result: false,
      message: res?.data?.message || "Cannot Get Shops",
    };
  }
};

export const getShopTimeLeft = async (
  dispatch: Dispatch<IActionType>,
  shopHash: string
): Promise<any> => {
  dispatch(getShopTimesLeftPending());
  const res: any = await getShopTimeEffect(shopHash);
  if (res && res.status >= 200 && res.status <= 300) {
    dispatch(getShopTimesLeftSuccess(res.data?.data || null));
    return {
      result: true,
      message: res.data?.message || "Get Shop Time Successful",
      time: res.data?.data || 0,
    };
  } else {
    dispatch(
      getShopTimesLeftFailed(res?.data?.message || "Cannot Get Shop Time")
    );
    return {
      result: false,
      message: res?.data?.message || "Cannot Get Shop Time",
    };
  }
};
