import React, { useState } from "react";
import { Layout, Modal } from "antd";

import { HomeContainer } from "./dashboard.styles";
import { WrapContent } from "../../utility/styledComponent/shared/WrapContent";
import Header from "./partial/Header";
import Sidebar from "./partial/Sidebar";
import { toggleMenuMobile, toggleSidebarMobile } from "../../utility/helpers";
import HeaderSidebar from "./partial/HeaderMenuSidebar";
import AntSuspense from "../../components/UI/AntSuspense";
import SignInForm from "../../components/Form/SignInFormApp";

const DashboardLayout: React.FC = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <HomeContainer>
                <Layout className="layout" id="DashboardLayout">
                    <Header />
                    <Layout className="layout-body">
                        <div className="container-sidebar" style={{display:'initial'}}>
                            <Sidebar showModalLogin={showModal} />
                            <div
                                className="bg-close"
                                onClick={(e) => toggleSidebarMobile()}
                            ></div>
                        </div>
                        <Layout.Content className="layout-content site-layout-background">
                            <WrapContent position="relative" height="100%">
                                {props.children}
                            </WrapContent>
                        </Layout.Content>
                        <div className="container-menu display-mb">
                            <HeaderSidebar />
                            <div
                                className="bg-close"
                                onClick={(e) => toggleMenuMobile()}
                            ></div>
                        </div>
                        <Modal title={null} footer={null}  open={isModalOpen} onCancel={handleCancel}>
                            <AntSuspense>
                                <SignInForm onClose={handleCancel} />
                            </AntSuspense>
                        </Modal>
                    </Layout>
                </Layout>
            </HomeContainer>
        </>
    );
};

export default DashboardLayout;
