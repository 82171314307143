export const convertDaysAndMinutes = (
  value: number,
  data: "days" | "minutes"
) => {
  if (data === "days") {
    return Number(value) * 1440;
  }

  return Math.round(Number(value) / 1440);
};
