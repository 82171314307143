import { axiosInstance } from "../../../configs/axiosConfig";
import { IResponseApi } from "../../interface/commonInterface";
import { IShopViewModel } from "../../interface/viewModel/shopViewModel";
import { IPaginationEffect } from "./interface";

export const getShopListEffect = async (
    paginationEffect: IPaginationEffect
) => {
    const dataSend = {
        limit: paginationEffect.pagination.pageSize,
        page: paginationEffect.pagination.current,
    };

    try {
        return await axiosInstance.get<IResponseApi<IShopViewModel>>(
            "/shop/all",
            {
                params: dataSend,
            }
        );
    } catch (error : any) {
        return error;
    }
};

//get a Shop by id
export const getShopByIdEffect = async (shopHash: string) => {
    try {
        return await axiosInstance.get<IResponseApi<IShopViewModel>>(
            `/shop/detail/${shopHash}`
        );
    } catch (error : any) {
        return error;
    }
};

export const cancelShopPlanEffect = async ({ plan_id, shop_hash }: any) => {
    const data = { id: plan_id, store: shop_hash };
    try {
        return await axiosInstance.post<IResponseApi<IShopViewModel>>(
            `/cancel-plan`,
            data
        );
    } catch (error : any) {
        return error;
    }
};

export const getShopTimeEffect = async (shopHash: string) => {
    try {
        return await axiosInstance.get<IResponseApi<any>>(
            `/shop/wishlist/trial_day/${shopHash}`
        );
    } catch (error : any) {
        return error;
    }
};
