import Icon from "@ant-design/icons";
import { IconContainer } from "./icon.styles";

const Documentation = () => {
    return (
        <IconContainer>
            <span role="img">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.024"
                    height="20"
                >
                    <g data-name="Group 23">
                        <path
                            data-name="Path 20"
                            d="M19.672 4.256L14.75.214a.972.972 0 00-.608-.216H4.75a.963.963 0 00-.96.96v.96H.95a.949.949 0 00-.952.952v16.176a.949.949 0 00.952.952h18.12a.949.949 0 00.952-.952V4.992a.957.957 0 00-.35-.736zm-17.76-.424h1.88v14.256h-1.88zm16.2 14.256H5.704V1.92h8.1l4.312 3.528z"
                            fill="#707070"
                        />
                    </g>
                    <g data-name="Group 27">
                        <g data-name="Group 24">
                            <path
                                data-name="Path 21"
                                d="M15.851 15.869H7.967a.957.957 0 110-1.914h7.884a.957.957 0 110 1.914z"
                                fill="#707070"
                            />
                        </g>
                        <g data-name="Group 25">
                            <path
                                data-name="Path 22"
                                d="M15.851 11.269H7.967a.957.957 0 110-1.914h7.884a.957.957 0 110 1.914z"
                                fill="#707070"
                            />
                        </g>
                        <g data-name="Group 26">
                            <path
                                data-name="Path 23"
                                d="M14.185 6.669H7.967a.957.957 0 110-1.914h6.218a.957.957 0 110 1.914z"
                                fill="#707070"
                            />
                        </g>
                    </g>
                </svg>
            </span>
        </IconContainer>
    );
};

export const DocumentationIcon = (props: any) => {
    return <Icon component={Documentation} {...props} />;
};
