import "antd/dist/antd.css";
import AppRouter from "./Router";
import { AuthStateProvider } from "./utility/context/authContext";
import { ShopStateProvider } from "./utility/context/shopContext";
import { AppCss } from "./utility/styledComponent/shared/App";
import { IntegrationContextProvider } from "./utility/store/context/IntegrationContext/IntegrationContext";

function App() {
  return (
    <AuthStateProvider>
      <ShopStateProvider>
        <IntegrationContextProvider>
          <AppCss className="override">
            <AppRouter />
          </AppCss>
        </IntegrationContextProvider>
      </ShopStateProvider>
    </AuthStateProvider>
  );
}

export default App;
