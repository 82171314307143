export const GetAuthPending: string = "GetAuthPending";
export const GetAuthSuccess: string = "GetAuthSuccess";
export const GetAuthFailed: string = "GetAuthFailed";

export const GetAuthLogOut: string = "GetAuthLogOut";
export const GetAuthFinish: string = "GetAuthFinish";

export const GetResetTokenPending: string = "GetResetTokenPending";
export const GetResetTokenSuccess: string = "GetResetTokenSuccess";
export const GetResetTokenFailed: string = "GetResetTokenFailed";

export const GetLoginPending: string = "GetLoginPending";
export const GetLoginSuccess: string = "GetLoginSuccess";
export const GetLoginFailed: string = "GetLoginFailed";

export const ResetPasswordPending: string = "ResetPasswordPending";
export const ResetPasswordSuccess: string = "ResetPasswordSuccess";
export const ResetPasswordFailed: string = "ResetPasswordFailed";

export const GetLogoutPending: string = "GetLogoutPending";
export const GetLogoutSuccess: string = "GetLogoutSuccess";
export const GetLogoutFailed: string = "GetLogoutFailed";

export const GetRegisterPending: string = "GetRegisterPending";
export const GetRegisterSuccess: string = "GetRegisterSuccess";
export const GetRegisterFailed: string = "GetRegisterFailed";

export const RefreshTokenPending: string = "RefreshTokenPending";
export const RefreshTokenSuccess: string = "RefreshTokenSuccess";
export const RefreshTokenFailed: string = "RefreshTokenFailed";

export const GetResetPasswordCodePending: string =
  "GetResetPasswordCodePending";
export const GetResetPasswordCodeSuccess: string =
  "GetResetPasswordCodeSuccess";
export const GetResetPasswordCodeFailed: string = "GetResetPasswordCodeFailed";

export const ConfirmResetPasswordCodePending: string =
  "ConfirmResetPasswordCodePending";
export const ConfirmResetPasswordCodeSuccess: string =
  "ConfirmResetPasswordCodeSuccess";
export const ConfirmResetPasswordCodeFailed: string =
  "ConfirmResetPasswordCodeFailed";
