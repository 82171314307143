import React, {lazy} from "react";

const SelectShopHash = lazy(() => import('../../../components/SelectShop'));
const PendingLayout = lazy(() => import('../../../layouts/Pending'));

function SelectShop() {
    return (
        <>
            <PendingLayout>
                <SelectShopHash/>
            </PendingLayout>
        </>
    );
}

export default SelectShop;
