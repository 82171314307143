import { message } from "antd";
import { Dispatch } from "react";
import { IActionType } from "../../interface/commonInterface";
import { failed, pending, success } from "../action/authAction/authAction";
import { getKlaviyoKeys } from "../effect/integrations";

type resType = {
  status: number;
  data: {
    publicKey: any;
    data: any;
  };
};

export const getKlaviyoIntegration = async (
  dispatch: Dispatch<IActionType>
) => {
  dispatch(pending());
  const getAPI = await getKlaviyoKeys()
    .then((res: resType) => {
      if (res && res.status >= 200 && res.status <= 300) {
        const activate = !!res?.data?.publicKey;
        dispatch(success(activate));
        return Promise.resolve({
          result: true,
          response: activate,
        });
      } else {
        dispatch(
          failed("Something went wrong ! Couldn't get authentication details")
        );
      }
    })
    .catch((err) => {
      const error =
        err.response?.data?.title ||
        "Something went wrong ! Couldn't get authentication details";
      dispatch(failed(error));

      // if (!(err instanceof TypeError)) {
      //   message.error(error);
      // }

      return Promise.reject({
        result: false,
        response: error,
      });
    });
};
