export enum Roles {
  TwcReportsUser = "twc-reports-user",
  TwcStoreOwner = "twc-store-owner",
  TwcStoreUser = "twc-store-user",
  TwcPosManager = "twc-pos-manager",
  StoreOwner = "store-owner",
  TwcSuperUser = "twc-super-user",
  TwcGroupOwner = "twc-group-owner",
  OldAuthMethod = "old-auth-method",
}
