import { StorageKeys } from "../../../constant/storageKeys";
import { toCronExpression } from "../../../views/pages/Dashboard/components/Utils";
import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";
const session_user =
    localStorage.getItem("SHOP_HASH") ||
    localStorage.getItem(StorageKeys.Tenant);

export const postDotDigitalCredentials = async (data: any) => {
    try {
      const req = {
        apiUser: data?.apiUser,
        apiPassword: data?.apiPassword,
      };
      return await axiosDashboard.post(
        `/services/eventcollector/api/v1/marketing/dotdigital/byTenant?tenantId=${session_user}`,
        req
      );
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const getDotDigitalUser = async () => {
    try {
      return await axiosDashboard.get(
        `/services/eventcollector/api/v1/marketing/dotdigital/byTenant?tenantId=${session_user}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  };
  