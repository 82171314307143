import React, {useReducer, createContext} from 'react'
import {
    configEmailDetailReducer, configEmailUpdateReducer,
    initConfigEmailDetailState, initConfigEmailUpdateState
} from "../store/reducer/configEmailReducer";
import {IConfigEmailContext} from "./interface";


const ConfigEmailStateContext = createContext<IConfigEmailContext>({
    configEmailDetailState: initConfigEmailDetailState,
    configEmailUpdateState: initConfigEmailUpdateState,
    detailDispatch: () => null,
    updateDispatch: () => null,
});

export function GetConfigEmailStateContext(): IConfigEmailContext {
    const context = React.useContext(ConfigEmailStateContext);
    if (context === undefined) {
        throw new Error('ConfigEmailStateContext not found');
    }
    return context;
}

// Provider
export const ConfigEmailStateProvider: React.FC = ({children}) => {
    const [configEmailDetailState, detailDispatch] = useReducer(
        configEmailDetailReducer,
        initConfigEmailDetailState
    );

    const [configEmailUpdateState, updateDispatch] = useReducer(
        configEmailUpdateReducer,
        initConfigEmailUpdateState
    );


    return (
        <ConfigEmailStateContext.Provider
            value={{
                configEmailDetailState, configEmailUpdateState,
                detailDispatch, updateDispatch,
            }}

        >
            {children}
        </ConfigEmailStateContext.Provider>
    )
}
