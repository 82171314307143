import { fromShop } from "./../authServices/jwt/index";
import Axios, { AxiosRequestConfig } from "axios";
import { sessionShopHash, sessionToken } from "../authServices/jwt";
import { message } from "antd";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const axiosInstance = Axios.create({
  baseURL: baseUrl,
});

export const axiosInstanceLogin = Axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  const _sessionToken = sessionToken.getToken();
  if (_sessionToken) {
    config.headers.Authorization = "Bearer " + _sessionToken;
  }
  return config;
});
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: any) {
    if (error.response.status === 401 || error.response.status === 403) {
      // sessionToken.clearToken();
      // sessionShopHash.clearShopHash();
      // window.sessionStorage.removeItem(fromShop);
      // window.location.reload();
    } else if (error.response.status === 503) {
      message.error("You have been logged out. Please log in again");
      window.location.href = '/auth/sign-in';
    }
    return Promise.reject(error);
  }
);
axiosInstanceLogin.interceptors.request.use(function (
  config: AxiosRequestConfig
) {
  return config;
});
axiosInstanceLogin.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: any) {
    if (error.response.status === 503) {
      message.error("You have been logged out. Please log in again");
      window.location.href = '/auth/sign-in';
    }
    return Promise.reject(error);
  }
);