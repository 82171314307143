import styled from "styled-components";

export const SignInContainer = styled.div`
    form {
        border: 1px solid #d6d6d6;
        padding: 30px 30px 15px;
        .footer {
            .ant-form-item-label {
                opacity: 0;
            }
            .btn--container {
                
                justify-content: flex-start;
                button{
                    width: 100%;
                    margin-top: 10px;
                    min-height: 35px;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
        .button{
            margin-bottom: 15px;
        }
        .ant-form-item-label >label{
            height: auto;
        }
        .ant-form-item{
            margin-bottom: 20px;
        }
    }
    .btn--link{
        text-align: right;
    }
    .form-custom-title{
        font-size: 24px;
        position: relative;
        top: -8px;
    }

    & .mb-0 {
        margin-bottom: 0;
    }

    & .alert-box {
        margin-bottom: 15px;
    }

    @media (max-width: 576px) {
        form {
            padding: 24px;
            .ant-form-item {
                margin-bottom: 12px;
            }
            .footer {
                .ant-form-item-label {
                    display: none;
                }
                .btn--container {
                    justify-content: flex-start;
                    margin-bottom: 12px;
                }
            }
        }
    }
`;
