import { LogoutOutlined } from "@ant-design/icons";
import { ConfigurationIcon } from "../../assets/icon/ConfigurationIcon";
import { DashboardIcon } from "../../assets/icon/DashboardIcon";
import { DocumentationIcon } from "../../assets/icon/DocumentationIcon";
import { ReportingIcon } from "../../assets/icon/ReportIcon";
import { UserIcon } from "../../assets/icon/UserIcon";

interface category {
  id?: string;
  key?: string;
  icon?: React.ReactNode;
  label?: string;
  items?: subCategory[];
}

interface subCategory {
  key?: string;
  tooltip?: string;
  label?: string;
}

export const categories: category[] = [
  {
    id: "1",
    key: "dashboard",
    icon: <DashboardIcon />,
    label: "dashboard",
    items: [],
  },
  // {
  //   id: "2",
  //   key: "subscription",
  //   icon: <SubscriptionIcon />,
  //   label: "subscription",
  //   items: [],
  // },
  {
    id: "3",
    key: "integrations",
    icon: <ConfigurationIcon />,
    label: "Integrations",
    items: [],
  },
  {
    id: "4",
    key: "app-configurations",
    icon: <ConfigurationIcon />,
    items: [
      { key: "launch-point" },
      { key: "add-items-to-wishlist" },
      { key: "wishlist-page" },
    ],
    label: "app configuration",
  },
  // {
  //   id: "9",
  //   key: "notifications",
  //   icon: <ConfigurationIcon />,
  //   items: [{ key: "wishlist" }, { key: "quotes" }],
  //   label: "app configuration",
  // },
  {
    id: "10",
    key: "user-administration",
    icon: <UserIcon />,
    items: [
      {
        label: "Add User",
        key: "users",
        tooltip: "",
      },
      {
        label: "Add Staff",
        key: "staff",
        tooltip: "",
      },
    ],
    label: "user administration",
  },
  {
    id: "5",
    key: "reporting",
    icon: <ReportingIcon />,
    label: "reporting",
    items: [
      { label:"Product Query", key: "product-report",tooltip:"All products and their occurrences on wishlists for a given date period (Shopify only report)" },
      // { label:"Wishlist Report (Shopify)", key: "wishlist-report",tooltip:"Summary of all wishlists created during a given date period (Shopify only report)" },
      {
        key: "customer-query",
        tooltip:
          "Show all customer wishlists created by customers in a given date range",
      },
      {
        key: "customer-interest",
        tooltip: "",
      },
      // {
      //   key: "product-query",
      //   tooltip:
      //     "All products and their occurrences on wishlists for a given date period",
      // },
      {
        key: "top-products",
        tooltip:
          "Top products based upon the number of times a product appears on all wishlists for a given date range.  Show by product, product/colour, or product/colour/size",
      },
      {
        key: "product-conversion",
        tooltip:
          "Summary of all conversions by product within a given date range",
      },
      {
        key: "product-conversion-detail",
        tooltip: "All product conversions within a given date range",
      },
      // {
      //   key: "all-customers",
      //   tooltip:
      //     "A summary of all customers that created wishlists in a given date range",
      // },
      {
        key: "customer-conversions",
        tooltip:
          "Total conversions for individual customers during a given date range",
      },
      {
        key: "wishlist",
        tooltip:
          "Show all wishlists created by customers in a given date range",
      },

      {
        key: "wishlist-interactions",
        tooltip: "All wishlist interactions for a given date range",
      },
    ],
  },
  // {
  //   id: "3",
  //   key: "pos-config",
  //   icon: <ConfigurationIcon />,
  //   items: [
  //     { key: "staff" },
  //     { key: "outlet-store" },
  //     { key: "outlet-config" },
  //   ],
  //   label: "Outlet",
  // },
  {
    id: "6",
    key: "documentation",
    icon: <DocumentationIcon />,
    label: "documentation",
    items: [],
  },
  // {
  //   id: "7",
  //   key: "diagnostics",
  //   icon: <IntegrationIcon />,
  //   label: "Diagnostics",
  //   items: [],
  // },
  {
    id: "8",
    key: "logout",
    icon: <LogoutOutlined style={{ fontSize: "20px" }} />,
    // items: [{ key: "sign-out" }],
    items: [],
  },
];
