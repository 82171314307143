// eslint-disable-next-line
export default {
    "en": {
        "translation": {
        }
    }
}

export const ConfigStep = {
    LaunchPointAsHeader: [
        `1. Go to Store Admin`,
        `2. Click Online Store > Action > Edit code`,
        `3. Go to Sections folder > Open file  'Header.liquid' > Find text: "<div class="site-header__icons-wrapper"> Embed code below this text`,
        {
            html: true,
            content: `<div class="osc-css launch-point-header-wrapper">
            <button class="launch-point-header-button" >
              <span class="launch-point-header-button-icon">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="heart" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z">
                  </path>
                </svg>
              </span>
              <span class="button-text"></span>
            </button>
            </div>`,
        },
        `4. Click on Save button`,
    ],
    LaunchPointAsMenuItem: [
        `1. Go to Store Admin`,
        `2. Click Online Store > Navigation > Main Menu > Add Menu items`,
        `3. Enter Name = Wishlist, select Link = Pages/Wishlist`,
        `4. Click on Add button`,
        `5. Click on Save button`,
    ],
    CollectionPageWishlist: [
        `1. Go to Store Admin`,
        `2. Click Online Store > Action > Edit code`,
        `3. Go to Snippets folder > Open file "{{fileGuide}}" > Find text: "{{textNav}}"`,
        `4. Copy the following code and paste it below this line: "{{textNav}}"`,
        {
            html: true,
            content: 
            `<div class="osc-css wrapper-button-collection">
                      <div class= "button-collection-wishlist button-{{ {{productObject}}.id }}" 
                        data-product-id='{{ {{productObject}}.id }}' 
                        data-has-only-variant='{{ {{productObject}}.has_only_default_variant }}'
                        data-product-title='{{ {{productObject}}.title }}'
                      >
                          <span class="button-icon"></span>
                          <span class="button-text"></span>
                      </div>
             </div>
              {% unless product == empty %}
              <script type="application/json" id="ProductJson-{{ {{productObject}}.id }}">
                {{ {{productObject}} | json }}
              </script>
              <script type="application/json" id="OptionJson-{{ {{productObject}}.id }}">
                {{ {{productObject}}.options_with_values | json }}
              </script>
            {% endunless %}`
        },
        `5. Click on Save button`,
    ]
}