import { Redirect } from "react-router-dom";
import { GetAuthStateContext } from "../context/authContext";
import DashboardLayout from "../../layouts/Dashboard";
import AntSuspense from "../../components/UI/AntSuspense";
import { CategoryKeys } from "../../configs/data/category.enum";
import { checkUserPagePermission } from "../helpers/checkUserPagePermission";
import { sessionToken } from "../../authServices/jwt";

export const useAuthChecking = () => {
  const { authState } = GetAuthStateContext();

  const checkAuth = (Component: JSX.Element): JSX.Element => {
    if (!authState.auth) {
      return <Redirect to="/auth" />;
    }

    return (
      <DashboardLayout>
        <AntSuspense>{Component}</AntSuspense>
      </DashboardLayout>
    );
  };

  const checkPermission = (
    Component: JSX.Element,
    pathKey: CategoryKeys
  ): JSX.Element | null => {
    if (!Component || !authState.auth) {
      return null;
    }

    const roles = authState.auth.status;

    if (!roles) {
      return null;
    }

    const isAccess = checkUserPagePermission(roles, pathKey);
    
    if (!isAccess) {
      return null;
    }

    return <div>{Component}</div>;
  };

  const checkPageAccess = (Component: JSX.Element, pathKey: CategoryKeys) => {
    let RenderComponent = checkAuth(Component);

    const isAccess = checkPermission(RenderComponent, pathKey);
    if (isAccess && pathKey === "app-configurations" && !sessionToken.getToken()) return <Redirect to="/" />;

    if (!isAccess) {
      return <Redirect to="/" />;
    }

    return <div>{RenderComponent}</div>;
  };

  return { checkPageAccess, checkPermission, checkAuth };
};
