import { StorageKeys } from "../../../constant/storageKeys";
import { toCronExpression } from "../../../views/pages/Dashboard/components/Utils";
import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";

export type ActiveItem = { active: boolean; notificationService: string }; 

export const postKlaviyoKeys = async (data: any) => {
  try {
    const req = {
      privateKey: `pk_${data?.privateKey}`,
      publicKey: data?.publicKey,
    };
    const session_user =
    localStorage.getItem("SHOP_HASH") ||
    localStorage.getItem(StorageKeys.Tenant);
    
    return await axiosDashboard.put(
      `/services/eventcollector/api/v1/marketing/klaviyo/byTenant?tenantId=${session_user}`,
      req
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getIntegrationDetails = async () => {
  try {
   const session_user =
    localStorage.getItem("SHOP_HASH") ||
    localStorage.getItem(StorageKeys.Tenant);
    return await axiosDashboard.get<ActiveItem[]>(
      `/services/eventcollector/api/v1/marketing/active/byTenant?tenantId=${session_user}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getKlaviyoKeys = async () => {
  try {
   const session_user =
    localStorage.getItem("SHOP_HASH") ||
    localStorage.getItem(StorageKeys.Tenant);
    return await axiosDashboard.get(
      `/services/eventcollector/api/v1/marketing/klaviyo/byTenant?tenantId=${session_user}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const cancelIntegration = async (marketing: string) => {
  try {
    const session_user =
    localStorage.getItem("SHOP_HASH") ||
    localStorage.getItem(StorageKeys.Tenant);
    return await axiosDashboard.delete(
      `/services/eventcollector/api/v1/marketing/${marketing.toLowerCase()}/byTenant?tenantId=${session_user}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postKlaviyoNotificationDetails = async (data: any) => {
  try {
    return await axiosDashboard.get(
      "/services/productsvc/api/v2/products/838a12e1-dd19-4243-b798-ebb10d3e9fbd/mockData"
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getConversionReport = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/product-conversion-report",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTopProductReport = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/top-wishlist-product",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWishlistReport = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/wishlist-report",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWishlistSubTable = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/wishlistproductreport",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getConversionSummaryReport = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/product-conversion-summary-report",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerReport = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/customer-report",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerConversionReport = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/customer-conversion-report",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInteractionReport = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/twcreports/api/analytics/interaction-report",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

interface reqType {
  is_active: Boolean;
  is_scheduled: Boolean;
  notification: string;
  notificationService: string;
  template_id?: string | null;
  outOfStock?: boolean;
  reminderTime?: string;
  schedule?: string | null;
  reminderDays?: string | null;
  stockThreshold?: number | null;
  job_parameters?: jobparamsType | undefined;
  configurable_delay?: string | null;
}
interface jobparamsType {
  enableUTM?: boolean | undefined;
  utm_medium?: string | null | undefined;
}
export const postNotificationDetails = async (
  data: any,
  params: URLSearchParams
) => {
  try {
    let jobParams: jobparamsType | undefined;
    if (
      data?.enableUTM === true ||
      data?.enableUTM === false ||
      data?.utm_medium ||
      data?.utm_medium === ""
    ) {
      jobParams = {};
      if (data?.enableUTM === true || data?.enableUTM === false) {
        jobParams["enableUTM"] = data?.enableUTM;
      }
      if (data?.utm_medium || data?.utm_medium === "") {
        jobParams["utm_medium"] = data?.utm_medium;
      }
    }
    const req: reqType = {
      is_active: data?.active,
      is_scheduled: data?.active,
      notification: data?.name,
      notificationService: data?.notificationService,
      outOfStock: data?.outOfStock,
      reminderTime: data?.reminderTime,
      reminderDays: data?.reminderDays,
      configurable_delay: data?.configurable_delay,
      job_parameters: jobParams,
    };
    if (data?.active && (data?.schedule || data?.delaySchedule)) {
      const type = data?.schedule ? "days" : "min";
      req["schedule"] = toCronExpression(
        data?.schedule || data?.delaySchedule,
        type
      );
    }
    if (data?.active && data?.stockThreshold) {
      req["stockThreshold"] = parseInt(data?.stockThreshold, 10);
    }
    if (data?.template_id) {
      req["template_id"] = data?.template_id;
    }

    return await axiosDashboard.put(
      "/services/eventcollector/api/v1/jobs",
      req,
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getNotificationDetails = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get(
      "/services/eventcollector/api/v1/jobs/configured/byTenant",
      { params }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getNotificationIndividual = async (params: URLSearchParams) => {
  try {
    return await axiosDashboard.get("/services/eventcollector/api/v1/jobs", {
      params,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
