import { message } from "antd";
import { useEffect, useState } from "react";
import { sessionShopHash, sessionToken } from "../../authServices/jwt";
import { GetAuthStateContext } from "../context/authContext";
import { GetShopStateContext } from "../context/shopContext";
import { getAuth, refreshToken } from "../store/middlerware/authMiddleware";
import { getShopById } from "../store/middlerware/shopMiddleware";

const useAuthenticateAndLoadShop = () => {
  const { authState, dispatch } = GetAuthStateContext();
  const { singleDispatch } = GetShopStateContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleAuthenticationAndShopLoad = async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams(window.location.search);
        const shopHashFromParams = params.get("shop_hash") || "";
        if (shopHashFromParams) {
          sessionShopHash.setShopHash(shopHashFromParams);
        }

        const sessionShopHashValue = sessionShopHash.getShopHash();
        const sessionTokenExists = !!sessionToken.getToken();
        const accessTokenExists = !!authState.accessToken; // Assuming authState is accessible

        if (sessionTokenExists && !accessTokenExists) {
          const refreshTokenResult = await refreshToken(dispatch);
          if (!refreshTokenResult.result) {
            throw new Error(refreshTokenResult.message);
          }

          const authResult = await getAuth(dispatch);
          if (!authResult.result) {
            throw new Error(authResult.message);
          }

          if (
            sessionShopHashValue &&
            !authResult.data.shops.find(
              (shop: any) => shop.shop_hash === sessionShopHashValue
            )
          ) {
            throw new Error("Shop hash not found in authorized shops.");
          }

          const shopRes = await getShopById(
            singleDispatch,
            sessionShopHashValue
          );
          if (!shopRes?.result) {
            throw new Error("Failed to load shop details.");
          }
        }
      } catch (error: any) {
        message.error(error.message || "An error occurred");
        sessionToken.clearToken();
        window.location.reload();
      } finally {
        setLoading(false);
      }
    };

    handleAuthenticationAndShopLoad();
  }, []);

  return loading;
};

export default useAuthenticateAndLoadShop;
