import axios from "axios";
import { authLocalStorage } from "../../../authServices/jwt";
import { LoginResponse } from "./libs/types/LoginResponse";

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: false,
});

const tokenURL = "auth/realms/twcMain/protocol/openid-connect/token";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET as string;

class Keycloak {
  async getTenantToken(username: string, password: string) {
    try {
      const data = new URLSearchParams();
      data.append("client_id", CLIENT_ID);
      data.append("grant_type", "password");
      data.append("username", username);
      data.append("password", password);
      data.append("client_secret", CLIENT_SECRET);

      const response = await authInstance.post<LoginResponse>(
        tokenURL,
        data.toString()
      );

      return response;
    } catch (error) {
      console.error("Error getting tenant token:", error);
      throw error;
    }
  }

  async refreshToken() {
    try {
      const refreshToken = authLocalStorage.getRefreshToken();

      if (!refreshToken) return null;

      const data = new URLSearchParams();
      data.append("client_id", CLIENT_ID);
      data.append("grant_type", "refresh_token");
      data.append("refresh_token", refreshToken);
      data.append("client_secret", CLIENT_SECRET);

      const response = await authInstance.post(tokenURL, data.toString());

      return response;
    } catch (error) {
      return null;
    }
  }
}

export const keycloackService = new Keycloak();
