import React, {useReducer, createContext} from 'react'

import {
    emailCreateReducer,
    emailDeleteReducer,
    emailEditReducer,
    emailListReducer,
    emailSingleReducer,
    defaultEmailListReducer,
    initEmailCreateState,
    initEmailDeleteState,
    initEmailEditState,
    initEmailListState,
    initEmailSingleState,
    initDefaultEmailListState, initEmailExportState, emailExportReducer, initEmailActiveState, emailActiveReducer
} from "../store/reducer/emailReducer";
import {IEmailContext} from "./interface";

const EmailStateContext = createContext<IEmailContext>({
    defaultEmailListState: initDefaultEmailListState,
    emailListState: initEmailListState,
    emailCreateState: initEmailCreateState,
    emailDeleteState: initEmailDeleteState,
    emailActiveState: initEmailActiveState,
    emailEditState: initEmailEditState,
    emailSingleState: initEmailSingleState,
    emailExportState: initEmailExportState,
    listDispatch: () => null,
    createDispatch: () => null,
    deleteDispatch: () => null,
    editDispatch: () => null,
    singleDispatch: () => null,
    defaultListDispatch: () => null,
    exportDispatch: () => null,
    activeDispatch: () => null
});

export function GetEmailStateContext(): IEmailContext {
    const context = React.useContext(EmailStateContext);
    if (context === undefined) {
        throw new Error('EmailStateContext not found');
    }
    return context;
}

// Provider
export const EmailStateProvider: React.FC = ({children}) => {
    const [emailCreateState, createDispatch] = useReducer(
        emailCreateReducer,
        initEmailCreateState
    );
    const [emailDeleteState, deleteDispatch] = useReducer(
        emailDeleteReducer,
        initEmailDeleteState
    );
    const [emailEditState, editDispatch] = useReducer(
        emailEditReducer,
        initEmailEditState
    );
    const [emailSingleState, singleDispatch] = useReducer(
        emailSingleReducer,
        initEmailSingleState
    );
    const [emailListState, listDispatch] = useReducer(
        emailListReducer,
        initEmailListState
    );
    const [defaultEmailListState, defaultListDispatch] = useReducer(
        defaultEmailListReducer,
        initDefaultEmailListState
    );
    const [emailExportState, exportDispatch] = useReducer(
        emailExportReducer,
        initEmailExportState
    );
    const [emailActiveState, activeDispatch] = useReducer(
        emailActiveReducer,
        initEmailActiveState
    );

    return (
        <EmailStateContext.Provider
            value={{
                defaultEmailListState,
                emailCreateState,
                emailDeleteState,
                emailEditState,
                emailListState,
                emailSingleState,
                emailExportState,
                emailActiveState,
                createDispatch,
                deleteDispatch,
                editDispatch,
                listDispatch,
                singleDispatch,
                defaultListDispatch,
                exportDispatch,
                activeDispatch
            }}
        >
            {children}
        </EmailStateContext.Provider>
    )
}
