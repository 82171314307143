import { Roles } from "../../configs/rolePemisions/roles.enum";

export type SubCategory = { key: string; tooltrip: string };

export const getFilterSubmenuByRole = (
  roles: string[],
  items: SubCategory[]
) => {
  if (roles.includes(Roles.TwcSuperUser)) {
    return items.filter((item) => item.key !== "product-query");
  }

  return items;
};
