import { RolePermissions } from "../../configs/rolePemisions";

export const getUserVisitPermission = (roles: string[], pageName: string) => {
  const pathNames: string[] = [];

  roles.forEach((role) => {
    const pages = RolePermissions[role as keyof typeof RolePermissions];

    if (pages) pathNames.push(...pages);
  });

  const isRoleHasPermission = pathNames.find((path) => path === pageName);

  return Boolean(isRoleHasPermission);
};
