import axios, { AxiosRequestConfig } from "axios";

import { keycloackService } from "../../../../api/services/keycloack/keycloack-auth.service";
import { LocalStorageKeys } from "../../../../authServices/jwt/libs/LocalStorage.enum";
import { StorageKeys } from "../../../../constant/storageKeys";
import { authLocalStorage } from "../../../../authServices/jwt";
import { message } from "antd";

export const axiosDashboard = axios.create({
   baseURL: process.env.REACT_APP_TWC_BASE_URL,
});

axiosDashboard.interceptors.request.use(function (config: AxiosRequestConfig) {
   let session_token;
   const accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
   const userManagementAccessKey = localStorage.getItem("user-management-access-key");

   if (accessToken && accessToken !== "undefined") {
      session_token = accessToken;
   } else if (userManagementAccessKey && userManagementAccessKey !== "undefined") {
      session_token = userManagementAccessKey;
   } else {
      session_token = null;
   }

   const TWC_TENANT = localStorage.getItem(LocalStorageKeys.INITIAL_TENANT);
   if (TWC_TENANT && session_token) {
      config.headers = {
         ...config.headers,
         Authorization: `Bearer ${session_token}`,
         "X-TWC-Tenant": TWC_TENANT,
      };
   } else {
      return config;
   }
   return config;
});

axiosDashboard.interceptors.response.use(
   function (response) {
      return Promise.resolve(response);
   },
   async function (error) {
      const isKlaviyoPutRequest =
         error.config.method === "put" && error.config.url.includes("/services/eventcollector/api/v1/marketing/klaviyo/byTenant");

      if (error.response.status === 504 && isKlaviyoPutRequest) {
         return Promise.reject(error);
      }

      if (!error.config._retry) {
         error.config._retry = true;

         const token = await keycloackService.refreshToken();

         authLocalStorage.setAuthTokens(token?.data.access_token, token?.data.refresh_token);

         return axiosDashboard(error.config);
      } else if (error.response.status === 503) {
         message.error("You have been logged out. Please log in again");
         window.location.href = "/auth/sign-in";
      }
      return Promise.reject(error);
   }
);
