export const GetDefaultEmailListPending: string = 'GetDefaultEmailListPending';
export const GetDefaultEmailListSuccess: string = 'GetDefaultEmailListSuccess';
export const GetDefaultEmailListFailed: string = 'GetDefaultEmailListFailed';

export const GetEmailListPending: string = 'GetEmailListPending';
export const GetEmailListSuccess: string = 'GetEmailListSuccess';
export const GetEmailListFailed: string = 'GetEmailListFailed';

export const CreateEmailPending: string = 'CreateEmailPending';
export const CreateEmailSuccess: string = 'CreateEmailSuccess';
export const CreateEmailFailed: string = 'CreateEmailFailed';

export const EditEmailPending: string = 'EditEmailPending';
export const EditEmailSuccess: string = 'EditEmailSuccess';
export const EditEmailFailed: string = 'EditEmailFailed';

export const GetEmailByIdPending: string = 'GetEmailByIdPending';
export const GetEmailByIdSuccess: string = 'GetEmailByIdSuccess';
export const GetEmailByIdFailed: string = 'GetEmailByIdFailed';

export const DeleteEmailPending: string = 'DeleteEmailPending';
export const DeleteEmailSuccess: string = 'DeleteEmailSuccess';
export const DeleteEmailFailed: string = 'DeleteEmailFailed';
export const DeleteEmailReset: string = 'DeleteEmailReset';

export const GetEmailThumbnailPending: string = 'GetEmailThumbnailPending';
export const GetEmailThumbnailSuccess: string = 'GetEmailThumbnailSuccess';
export const GetEmailThumbnailFailed: string = 'GetEmailThumbnailFailed';

export const ActiveEmailPending: string = 'ActiveEmailPending';
export const ActiveEmailSuccess: string = 'ActiveEmailSuccess';
export const ActiveEmailFailed: string = 'ActiveEmailFailed';
export const ActiveEmailReset: string = 'ActiveEmailReset';
