import { createContext, useEffect, useState } from "react";
import { ActiveItem, getIntegrationDetails } from "../../effect/integrations";
import { GetAuthStateContext } from "../../../context/authContext";

interface IIntegrationContext {
  activatedIntegrations: ActiveItem[] | undefined;
  isError: string | null | boolean;
  setIsError?: (isError: string) => void;
  loading: boolean;
  resData: any;
  setResData?: (data: any) => void;
  reload?: () => void;
}

export const IntegrationContext = createContext<IIntegrationContext>({
  activatedIntegrations: [],
  isError: null,
  loading: false,
  resData: null,
});

const getIsActivate = (data: ActiveItem[]) => {
  if (!data || !data?.length) return [];

  // return data.some((item) => item.active);
  return data;
};

export const IntegrationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { authState } = GetAuthStateContext();

  const [activatedIntegrations, setActivatedIntegrations] = useState<ActiveItem[]>();
  const [isError, setIsError] = useState<string | null | boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resData, setResData] = useState<any>(null);

  const [toggle, setToggle] = useState<boolean>(false);

  useEffect(() => {
    if (!authState.auth?.tenant || !authState.auth?.accessToken) return;

    const fetchData = async () => {
      setLoading(true);
      setIsError(false);

      try {
        const { data } = await getIntegrationDetails();
        
        const isActivated = getIsActivate(data);
        setActivatedIntegrations(isActivated);

        setResData(data);
      } catch (error: any) {
        setIsError(error?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [toggle, authState.auth?.tenant, authState.auth?.accessToken]);

  const reload = () => {
    setToggle((prev) => !prev);
  };

  return (
    <IntegrationContext.Provider
      value={{
        activatedIntegrations,
        isError,
        setIsError,
        loading,
        resData,
        setResData,
        reload,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};
