export const GetShopByIdPending: string = "GetShopByIdPending";
export const GetShopByIdSuccess: string = "GetShopByIdSuccess";
export const GetShopByIdFailed: string = "GetShopByIdFailed";

export const CancelShopPlanPending: string = "CancelShopPlanPending";
export const CancelShopPlanSuccess: string = "CancelShopPlanSuccess";
export const CancelShopPlanFailed: string = "CancelShopPlanFailed";

export const GetShopListPending: string = "GetShopListPending";
export const GetShopListSuccess: string = "GetShopListSuccess";
export const GetShopListFailed: string = "GetShopListFailed";

export const GetShopTimeLeftPending: string = "GetShopTimeLeftPending";
export const GetShopTimeLeftSuccess: string = "GetShopTimeLeftSuccess";
export const GetShopTimeLeftFailed: string = "GetShopTimeLeftFailed";

export const Init: string = "FETCH_INIT";
export const Success: string = "FETCH_SUCCESS";
export const Failed: string = "FETCH_FAILURE";
