import { CheckCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Form, Input, InputNumber, Row, Spin, Switch, message } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import "./AppIntegrations.styles.css";
import { getDotDigitalUser, postDotDigitalCredentials } from "../../../utility/store/effect/dotDigitalIntegrations";
import { getNotificationDetails, postNotificationDetails } from "../../../utility/store/effect/integrations";

import { convertDaysAndMinutes } from "../../../utility/helpers/convertDaysAndMinutes";
import { cronToTime } from "../Dashboard/components/Utils";
import { DotdigitalUser } from "./libs/DotdigitalUser.type";
import { StorageKeys } from "../../../constant/storageKeys";

function Dotdigital() {
   const [form] = Form.useForm();
   const [reminderForm] = Form.useForm();
   const [priceDropForm] = Form.useForm();
   const [lowStockForm] = Form.useForm();
   const [backInStockForm] = Form.useForm();
   const [notifyMeForm] = Form.useForm();
   const [comingSoonForm] = Form.useForm();
   const [addItemForm] = Form.useForm();
   const [storeVisitForm] = Form.useForm();
   const [keyLoaded, setKeyLoad] = useState<boolean | undefined>(false);
   const [dataLoaded, setDataLoad] = useState<boolean | undefined>(false);
   const [data, setData] = useState<any>([]);
   const [errorMsg, setKeyError] = useState<string>("");
   const [saveBtnLoading, setSaveBtnLoading] = useState<Boolean>(false);
   const [notifSaveLoading, setNotifSaveLoading] = useState<any>({
      REMINDER: false,
      PRICE_DROP: false,
      LOW_STOCK: false,
      BACK_IN_STOCK: false,
      ADD_TO_WISHLIST: false,
      POST_STORE_VISIT: false,
      NOTIFY_ME: false,
      COMING_SOON: false,
   });
   const [fieldOnChange, setOnChange] = useState<any>({
      REMINDER: false,
      PRICE_DROP: false,
      LOW_STOCK: false,
      BACK_IN_STOCK: false,
      ADD_TO_WISHLIST: false,
      POST_STORE_VISIT: false,
      NOTIFY_ME: false,
      COMING_SOON: false,
   });
   const numberValidator = (rule: any, value: any, callback: any) => {
      if (isNaN(value)) {
         callback("Please enter a valid number");
      } else if (rule?.minValue && value < rule?.minValue) {
         callback(`Number should be greater than ${rule?.minValue} or equal to ${rule?.minValue}`);
      } else if (rule?.maxValue && value > rule?.maxValue) {
         callback(`Number should be less than ${rule?.maxValue} or equal to ${rule?.maxValue}`);
      } else {
         callback();
      }
   };
   const [status, setStatus] = useState<string[]>(["INITIALIZED"]);
   const [dotdigitalUser, setDotgitialUser] = useState<DotdigitalUser>({} as DotdigitalUser);

   const notificationValues = {
      apiUser: Form.useWatch("apiUser", form),
      apiPassword: Form.useWatch("apiPassword", form),
      sendReminderEmailInDays: Form.useWatch("configurable_delay", reminderForm),
      configurable_delay: Form.useWatch("configurable_delay", form),
      sendReminderNotification: Form.useWatch("active", reminderForm),
      OutOfStock1: Form.useWatch("OutOfStock", reminderForm),
      OutOfStock2: Form.useWatch("OutOfStock", priceDropForm),
      OutOfStock3: Form.useWatch("OutOfStock", lowStockForm),

      template_id1: Form.useWatch("template_id", reminderForm),
      template_id2: Form.useWatch("template_id", priceDropForm),
      template_id3: Form.useWatch("template_id", lowStockForm),
      template_id4: Form.useWatch("template_id", backInStockForm),
      template_id5: Form.useWatch("template_id", addItemForm),
      template_id6: Form.useWatch("template_id", storeVisitForm),
      template_id7: Form.useWatch("template_id", notifyMeForm),
      template_id8: Form.useWatch("template_id", comingSoonForm),

      sendPriceDropNotification: Form.useWatch("active", priceDropForm),
      delayPriceDropNotificationInMinutes: Form.useWatch("configurable_delay", priceDropForm),
      minStockLevelToTriggeredForLowStock: Form.useWatch("minStockLevelSchedule", lowStockForm),
      delayLowStockNotificationInMinutes: Form.useWatch("configurable_delay", lowStockForm),
      sendLowStockNotification: Form.useWatch("active", lowStockForm),
      minStockLevelToTriggeredForBackInStock: Form.useWatch("minStockLevelSchedule", backInStockForm),
      sendBackInStockNotification: Form.useWatch("active", backInStockForm),
      enableAddItemNotification: Form.useWatch("active", addItemForm),
      waitTimeForAddItemNotification: Form.useWatch("configurable_delay", addItemForm),
      enablePostStoreVisitNotification: Form.useWatch("active", storeVisitForm),
      waitTimeForStoreVisitNotification: Form.useWatch("configurable_delay", storeVisitForm),

      notifyDelayNotification: Form.useWatch("configurable_delay", notifyMeForm),
      sendNotifyMe: Form.useWatch("active", notifyMeForm),
      sendComingSoon: Form.useWatch("active", comingSoonForm),
      notifyMeMinStockLevel: Form.useWatch("minStockLevelSchedule", notifyMeForm),
   };

   const session_user = localStorage.getItem("SHOP_HASH") || localStorage.getItem(StorageKeys.Tenant);

   const updatedValues = (type: string, name: string, cron?: string) => {
      if (data?.length > 0) {
         let value: any = null;
         const valueObj = data?.find((item: any) => item.hasOwnProperty("notification") && item?.notification === type);
         if (!!valueObj && valueObj.hasOwnProperty(name)) {
            if (cron === "days") value = cronToTime(valueObj[name], cron);
            else if (cron === "min") value = cronToTime(valueObj[name], cron);
            else value = valueObj[name];
         } else value = null;
         return value;
      } else return null;
   };

   const initialValuesStoreVisit = {
      active: updatedValues("POST_STORE_VISIT", "is_active"),
      configurable_delay: updatedValues("POST_STORE_VISIT", "configurable_delay"),
   };
   const initialValuesAddItem = {
      active: updatedValues("ADD_TO_WISHLIST", "is_active"),
      configurable_delay: updatedValues("ADD_TO_WISHLIST", "configurable_delay"),
   };
   const initialValuesBackInStock = {
      minStockLevelSchedule: updatedValues("BACK_IN_STOCK", "stockThreshold"),
      active: updatedValues("BACK_IN_STOCK", "is_active") || false,
   };
   const initialValuesNotifyMe = {
      minStockLevelSchedule: updatedValues("NOTIFY_ME", "stockThreshold"),
      active: updatedValues("NOTIFY_ME", "is_active") || false,
      configurable_delay: updatedValues("NOTIFY_ME", "configurable_delay", "minutes"),
   };
   const initialValuesComingSoon = {
      active: updatedValues("COMING_SOON", "is_active"),
   };
   const initialValuesPriceDrop = {
      active: updatedValues("PRICE_DROP", "is_active") || false,
      configurable_delay: updatedValues("PRICE_DROP", "configurable_delay"),
   };
   const initialValuesLowStock = {
      minStockLevelSchedule: updatedValues("LOW_STOCK", "stockThreshold"),
      delaySchedule: updatedValues("LOW_STOCK", "configurable_delay"),
      active: updatedValues("LOW_STOCK", "is_active") || false,
   };
   const initialValuesReminder = {
      configurable_delay: updatedValues("REMINDER", "configurable_delay", "days"),
      active: updatedValues("REMINDER", "is_active") || false,
   };

   const currentStatus = {
      initialized: "INITIALIZED",
      savedKeys: "SAVED_KEYS",
      enabled: "ENABLED",
   };

   useEffect(() => {
      handleGetKeys();
      getData();
   }, []);

   const handleGetKeys = () => {
      setKeyLoad(true);
      setKeyError("");
      getDotDigitalUser()
         .then((res: any) => {
            form.setFieldValue("publicKey", res?.data?.publicKey);
            form.setFieldValue("privateKey", "");
            setDotgitialUser(res?.data);
            setStatus([res?.data, currentStatus.savedKeys]);
         })
         .catch((err: any) => {
            setKeyError(err.response?.data?.title);
            message.warning("Warning - no authentication credentials found");
            //   err.response?.status !== 404 &&
            //     message.error(
            //       err.response?.data?.title ||
            //         "Something went wrong ! Couldn't get authentication details"
            //     );
            console.log(err);
         })
         .finally(() => setKeyLoad(false));
   };
   const getData = () => {
      setDataLoad(true);
      const params = new URLSearchParams([
         ["notificationService", "DOTDIGITAL"],
         ["tenantId", session_user || ""],
      ]);
      getNotificationDetails(params)
         .then((res) => {
            if (res?.data) {
               // const filterData = res?.data.map((element: any) => {
               //   if (
               //     element?.notification === "REMINDER" &&
               //     !!element?.configurable_delay
               //   )
               //     formToKey[element?.notification].setFieldValue(
               //       "configurable_delay",
               //       convertDaysAndMinutes(element?.configurable_delay, "minutes")
               //     );
               // });

               handleUpdate(res?.data);
               setDataLoad(false);
            }
         })
         .catch((err) => {
            setDataLoad(false);
            //   err.response?.status !== 404 &&
            //     message.warning("Warning - no authentication credentials found");
            console.log(err);
         });
   };

   const formToKey: any = {
      REMINDER: reminderForm,
      PRICE_DROP: priceDropForm,
      LOW_STOCK: lowStockForm,
      BACK_IN_STOCK: backInStockForm,
      ADD_TO_WISHLIST: addItemForm,
      POST_STORE_VISIT: storeVisitForm,
      NOTIFY_ME: notifyMeForm,
      COMING_SOON: comingSoonForm,
   };

   const handleUpdate = (data: any) => {
      setData(data);
      data?.map((element: any) => {
         if (!formToKey[element?.notification]) return element;

         if (element?.configurable_delay) formToKey[element?.notification].setFieldValue("configurable_delay", element?.configurable_delay);

         if (element?.notification === "REMINDER" && !!element?.configurable_delay) {
            const convertedValue = convertDaysAndMinutes(element?.configurable_delay, "minutes");

            formToKey[element?.notification].setFieldValue("configurable_delay", convertDaysAndMinutes(element?.configurable_delay, "minutes"));
         }

         !!element?.stockThreshold && formToKey[element?.notification].setFieldValue("minStockLevelSchedule", element?.stockThreshold);
         formToKey[element?.notification].setFieldValue("OutOfStock", element.outOfStock);
         formToKey[element?.notification].setFieldValue("template_id", element.template_id);
         formToKey[element?.notification].setFieldValue("active", element.is_active);
         return element;
      });
   };

   const handleSubmitKeys = (values: any) => {
      setSaveBtnLoading(true);
      setKeyError("");
      postDotDigitalCredentials(values)
         .then((res) => {
            setStatus((e) => [...e, "SAVED_KEYS"]);
            message.success("Successfully saved authentication details");
            setSaveBtnLoading(false);
         })
         .catch((err) => {
            setSaveBtnLoading(false);
            setKeyError(err.response?.data?.title);
            message.error(err.response?.data?.title || "Something went wrong ! Couldn't save authentication details");
            console.log(err);
         });
   };
   // const handleEnableKlaviyo = (values: any) => {
   //   console.log(values);
   //   setEnableBtnLoading(true);
   //   postKlaviyoNotificationDetails(values)
   //     .then((res) => {
   //       setStatus((e) => [...e, "ENABLED"]);
   //       message.success("Successfully enabled klaviyo integration");
   //       setEnableBtnLoading(false);
   //     })
   //     .catch((err) => {
   //       setEnableBtnLoading(false);
   //       message.error(
   //         "Something went wrong ! Couldn't enable klaviyo integration"
   //       );
   //       console.log(err);
   //     });
   // };

   const handleNotificationChanges = (value: any, name: string) => {
      setNotifSaveLoading({ ...notifSaveLoading, [name]: true });
      const req = {
         name: name,
         active: value.active,
         schedule: value.schedule,
         delaySchedule: value.delaySchedule,
         stockThreshold: value.minStockLevelSchedule,
         notificationService: "DOTDIGITAL",
         outOfStock: value.OutOfStock,
         template_id: value.template_id,
         configurable_delay: name === "REMINDER" ? convertDaysAndMinutes(value.configurable_delay, "days") : value.configurable_delay,
      };
      const params = new URLSearchParams([
         ["notification", name],
         ["notificationService", "DOTDIGITAL"],
      ]);
      postNotificationDetails(req, params)
         .then((res) => {
            message.success("Successfully updated the notification details");
            setNotifSaveLoading({ ...notifSaveLoading, [name]: false });
            setOnChange({ ...fieldOnChange, [name]: false });
            // getData();
         })
         .catch((err) => {
            setNotifSaveLoading({ ...notifSaveLoading, [name]: false });
            message.error("Something went wrong ! Couldn't update the notification details");
            console.log(err);
         });
   };

   const handleChangeSwitch = (value: boolean, name: string, type: string, underFields: string[], form: any, disableSubmit: Boolean) => {
      if (value === false) {
         form.setFields(
            underFields.map((item: string) => ({
               name: item,
               errors: [],
            }))
         );
      }
      form.setFieldValue(name, value);
      if (!disableSubmit) form.submit();
   };

   const handleChangeField = (value: any, name: string, type: string, form: any) => {
      setOnChange({ ...fieldOnChange, [type]: true });
      form.setFieldValue(name, value);
   };
   const handleCancelBtn = (type: string, form: any) => {
      form.resetFields();
      setOnChange({ ...fieldOnChange, [type]: false });
   };

   useEffect(() => {
      form.setFieldsValue({
         apiUser: dotdigitalUser.apiUser,
         apiPassword: dotdigitalUser.apiPassword,
      });
   }, [dotdigitalUser.apiPassword, dotdigitalUser.apiUser]);

   return (
      <div className="p-[3%] px-[4%] leading-10">
         <Link className="flex items-center gap-1 !text-[#59c3b8]" to="/integrations">
            <LeftOutlined />
            Integrations
         </Link>
         <h1 className="font-medium text-3xl">Configure Dotdigital</h1>
         <div className="shadow-[0px_-1px_0px_2px_#59c3b8] p-[3%] px-[6%] !flex !flex-col gap-1">
            <div className="text-base text-gray-500">Before you enable Dotdigital, enter all the details and configure supported triggers.</div>
            <div className="text-sm text-gray-600">Refer our help docs to see how to build experiences using Dotdigital.</div>
            <Button className="!shadow-[0px_-0.5px_0px_1px_#59c3b8] !border-[#59c3b8] hover:!border-[#59c3b8] focus:!border-[#59c3b8] !bg-transparent !text-[#59c3b8] md:!w-[20%] !min-w-[20%] my-2">
               Learn More
            </Button>
         </div>

         <Divider />

         <div className=" grid sm:grid-cols-3 xs:grid-cols-3 gap-4 wrap">
            <div className="col-span-1">
               <h1 className="font-medium text-xl text-gray-600">General</h1>
               <div className="text-base font-bold text-gray-500">Integration status</div>
               <div className="text-sm text-gray-600">Indicates if the integration is currently enabled for your store.</div>
            </div>
            <div className="bg-[#fff] flex items-center px-7 text-base text-gray-500 col-span-2 !min-h-[80px]">
               <span>
                  Dotdigital integration on your store is &nbsp;
                  <b>{status.includes(currentStatus.savedKeys) ? "ENABLED" : "DISABLED"}</b>
               </span>
            </div>
         </div>

         <Divider />

         <div className=" grid sm:grid-cols-3 xs:grid-cols-3 gap-4 wrap">
            <div className="!col-span-1">
               <h1 className="font-medium text-xl text-gray-600">Authentication</h1>
               <div className="text-base font-bold text-gray-500">Authentication details</div>
               <div className="text-sm text-gray-600">API credentials for your Dotdigital account.</div>
            </div>
            <div className=" col-span-2">
               <div className="bg-[#fff]  px-7 p-5 text-base text-gray-500 !min-h-[80px]">
                  <Spin spinning={keyLoaded}>
                     <Form
                        name="basic"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}
                        className="keys-form"
                        form={form}
                        onFinish={handleSubmitKeys}
                        autoComplete="nope"
                     >
                        <Form.Item
                           label="API user"
                           name="apiUser"
                           className="keys-form-item !mb-1"
                           rules={[
                              {
                                 required: true,
                                 message: "Please input your API user!",
                              },
                              {
                                 min: 6,
                                 message: "Invalid format: Field should consist of 6 characters",
                              },
                           ]}
                        >
                           <Input
                              autoComplete={"nope"}
                              // disabled={status.includes(currentStatus.savedKeys)}
                           />
                        </Form.Item>
                        <br />
                        <Form.Item
                           label="Password"
                           name="apiPassword"
                           className="keys-form-item !mb-1 "
                           rules={[
                              {
                                 required: true,
                                 message: "Please input your password!",
                              },
                              {
                                 min: 8,
                                 message: "Invalid format: Field should consist atleast 8 characters ",
                              },
                           ]}
                        >
                           <Input.Password
                              autoComplete="off"
                              // prefix="pk_"
                              // disabled={
                              //   status.includes(currentStatus.savedKeys) ||
                              //   !!getKeys?.privateKey
                              // }
                           />
                        </Form.Item>
                     </Form>
                  </Spin>
               </div>
               {/* {!!errorMsg && (
            <Alert className="!mt-2" message={errorMsg} type="error" showIcon />
          )} */}
            </div>
         </div>
         <div className="btn-root !flex sm:!items-center !justify-end gap-3 my-2">
            {/* <Popconfirm
          title="Are you sure to Save Public key and Private key ?"
          onConfirm={confirmSave}
          onCancel={cancelSave}
          okButtonProps={{ loading: true }}
          disabled={false}
          okText="Yes"
          cancelText="No"
        > */}
            {errorMsg && !currentStatus.savedKeys && (
               <Alert className="!mt-2" message={"Warning - no authentication credentials found"} type="warning" showIcon />
            )}

            <Button
               type="primary"
               className="save-btn md:!w-[15%] xs:!w-full !min-w-[15%] my-2 "
               onClick={() => form.submit()}
               loading={!!saveBtnLoading}
               disabled={!(notificationValues.apiUser && notificationValues.apiPassword)}
            >
               Save
            </Button>

            {/* </Popconfirm> */}
         </div>

         <Divider />

         <div className=" grid sm:grid-cols-3 xs:grid-cols-3 gap-4 wrap">
            <div className="!col-span-1">
               <h1 className="font-medium text-xl text-gray-600">Notifications</h1>
               <div className="text-base font-bold text-gray-500">Configure email notifications to send to customers</div>
               <div className="text-sm text-gray-600">Specify the criteria for each notification type.</div>
            </div>
            <div className="col-span-2 ">
               <div className="shadow-[0px_-1px_0px_2px_#59c3b8] p-[3%] px-[6%] !flex !flex-col gap-1">
                  <div className="text-base text-gray-500">
                     Please setup experiences for the enabled events on Dotdigital as well to complete integration.
                  </div>
                  <div className="text-sm text-gray-600">Refer our tips to setup these events on Dotdigital.</div>
                  <Button className="!shadow-[0px_-0.5px_0px_1px_#59c3b8] !border-[#59c3b8] hover:!border-[#59c3b8] focus:!border-[#59c3b8] !bg-transparent !text-[#59c3b8] lg:!w-[20%] !min-w-[20%] my-2">
                     Learn More
                  </Button>
               </div>
               <br />

               <div className="wrap bg-[#fff] p-6 px-7">
                  <Spin spinning={dataLoaded}>
                     <Form
                        initialValues={initialValuesReminder}
                        form={reminderForm}
                        onFinish={(val) => handleNotificationChanges(val, "REMINDER")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 Send <b>reminder notifications</b> to your customers for items they have added to their Wishlist.
                              </p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.sendReminderNotification}
                                    onChange={(e) => handleChangeSwitch(e, "active", "REMINDER", ["configurable_delay"], reminderForm, false)}
                                 />
                                 &nbsp;&nbsp;
                                 <span>Send Reminder notifications</span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 name="configurable_delay"
                                 className="input-control !mb-0 "
                                 rules={[
                                    {
                                       required: notificationValues.sendReminderNotification,
                                       message: "This field is required!",
                                    },
                                    {
                                       validator: (rule, value, callback) => {
                                          return numberValidator(
                                             {
                                                ...rule,
                                                minValue: 1,
                                             },
                                             value,
                                             callback
                                          );
                                       },
                                    },
                                 ]}
                              >
                                 <span className="before:content-['Send_reminder_every']  after:content-['day(s)_(required)'] gap-2">
                                    <InputNumber
                                       parser={(string: string | undefined) => {
                                          return Math.floor(Number(string)) || null;
                                       }}
                                       min={0}
                                       className="!mx-2"
                                       disabled={!notificationValues.sendReminderNotification}
                                       value={notificationValues.sendReminderEmailInDays}
                                       onChange={(e) => handleChangeField(e, "configurable_delay", "REMINDER", reminderForm)}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.sendReminderNotification}
                                    value={notificationValues.template_id1}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "REMINDER", reminderForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="OutOfStock" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.OutOfStock1}
                                    onChange={(e) =>
                                       handleChangeSwitch(e, "OutOfStock", "REMINDER", ["configurable_delay"], reminderForm, fieldOnChange.REMINDER)
                                    }
                                 />
                                 &nbsp;&nbsp;
                                 <span>Don't send reminder for out of stock items</span>
                              </Form.Item>
                           </Col>

                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.REMINDER} onClick={() => handleCancelBtn("REMINDER", reminderForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => reminderForm.submit()}
                                 loading={!!notifSaveLoading.REMINDER}
                                 hidden={!fieldOnChange.REMINDER}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>

                     <Divider />

                     <Form
                        initialValues={initialValuesPriceDrop}
                        form={priceDropForm}
                        onFinish={(val) => handleNotificationChanges(val, "PRICE_DROP")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 Send a <b>price drop</b> notification to your customers when there is a price drop for items in their Wishlist.
                              </p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.sendPriceDropNotification}
                                    onChange={(e) => handleChangeSwitch(e, "active", "PRICE_DROP", ["delaySchedule"], priceDropForm, false)}
                                 />
                                 &nbsp;&nbsp;
                                 <span>Send price drop notifications</span>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="configurable_delay"
                                 className="input-control !mb-0"
                                 // rules={[
                                 //   {
                                 //     required:
                                 //       notificationValues.sendPriceDropNotification,
                                 //     message:
                                 //       "Number should be greater than 5 or equal to 5!",
                                 //   },
                                 //   {
                                 //     validator: (rule, value, callback) => {
                                 //       return numberValidator(
                                 //         {
                                 //           ...rule,
                                 //           minValue: 5,
                                 //         },
                                 //         value,
                                 //         callback
                                 //       );
                                 //     },
                                 //   },
                                 // ]}
                              >
                                 <span className="before:content-['Delay_price_drop_notifications_by'] after:content-['minutes_(required)']">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={5}
                                       className="!mx-2"
                                       disabled={!notificationValues.sendPriceDropNotification}
                                       value={notificationValues.delayPriceDropNotificationInMinutes}
                                       onChange={(e) => handleChangeField(e, "configurable_delay", "PRICE_DROP", priceDropForm)}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.sendPriceDropNotification}
                                    value={notificationValues.template_id2}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "PRICE_DROP", priceDropForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="OutOfStock" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.OutOfStock2}
                                    onChange={(e) =>
                                       handleChangeSwitch(
                                          e,
                                          "OutOfStock",
                                          "PRICE_DROP",
                                          ["configurable_delay"],
                                          priceDropForm,
                                          fieldOnChange.PRICE_DROP
                                       )
                                    }
                                 />
                                 &nbsp;&nbsp;
                                 <span>Don't send price drop notification for out of stock items</span>
                              </Form.Item>
                           </Col>
                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.PRICE_DROP} onClick={() => handleCancelBtn("PRICE_DROP", priceDropForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => priceDropForm.submit()}
                                 loading={!!notifSaveLoading.PRICE_DROP}
                                 hidden={!fieldOnChange.PRICE_DROP}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>

                     <Divider />

                     <Form
                        initialValues={initialValuesLowStock}
                        form={lowStockForm}
                        onFinish={(val) => handleNotificationChanges(val, "LOW_STOCK")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 Send a <b>low stock</b> notification to your customers when there are items in their Wishlist that are almost sold
                                 out.
                              </p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.sendLowStockNotification}
                                    onChange={(e) =>
                                       handleChangeSwitch(e, "active", "LOW_STOCK", ["delaySchedule", "minStockLevelSchedule"], lowStockForm, false)
                                    }
                                 />
                                 &nbsp;&nbsp;
                                 <span>Send low stock notifications</span>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="configurable_delay"
                                 className="input-control !mb-0"
                                 rules={[
                                    {
                                       required: notificationValues.sendLowStockNotification,
                                       message: "This field is required!",
                                    },
                                    // {
                                    //   validator: (rule, value, callback) => {
                                    //     return numberValidator(
                                    //       {
                                    //         ...rule,
                                    //         minValue: 5,
                                    //       },
                                    //       value,
                                    //       callback
                                    //     );
                                    //   },
                                    // },
                                 ]}
                              >
                                 <span className="before:content-['Delay_low_stock_notifications_by'] after:content-['minutes_(required)']">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={5}
                                       className="!mx-2"
                                       disabled={!notificationValues.sendLowStockNotification}
                                       value={notificationValues.delayLowStockNotificationInMinutes}
                                       onChange={(e) => handleChangeField(e, "configurable_delay", "LOW_STOCK", lowStockForm)}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="minStockLevelSchedule"
                                 className="input-control !mb-0"
                                 rules={[
                                    {
                                       required: notificationValues.sendLowStockNotification,
                                       message: "This field is required!",
                                    },
                                    { validator: numberValidator },
                                 ]}
                              >
                                 <span className="before:content-['Minimum_stock_level_for_the_low_stock_notification_to_be_triggered'] after:content-['(required)']">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={0}
                                       className="!mx-2"
                                       value={notificationValues.minStockLevelToTriggeredForLowStock}
                                       onChange={(e) => handleChangeField(e, "minStockLevelSchedule", "LOW_STOCK", lowStockForm)}
                                       disabled={!notificationValues.sendLowStockNotification}
                                    />
                                 </span>

                                 {/* &nbsp;&nbsp;
                    (required) */}
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.sendLowStockNotification}
                                    value={notificationValues.template_id3}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "LOW_STOCK", lowStockForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="OutOfStock" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.OutOfStock3}
                                    onChange={(e) =>
                                       handleChangeSwitch(
                                          e,
                                          "OutOfStock",
                                          "LOW_STOCK",
                                          ["configurable_delay", "minStockLevelSchedule"],
                                          lowStockForm,
                                          fieldOnChange.LOW_STOCK
                                       )
                                    }
                                 />
                                 &nbsp;&nbsp;
                                 <span>Don't send low stock notification for out of stock items</span>
                              </Form.Item>
                           </Col>
                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.LOW_STOCK} onClick={() => handleCancelBtn("LOW_STOCK", lowStockForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => lowStockForm.submit()}
                                 loading={!!notifSaveLoading.LOW_STOCK}
                                 hidden={!fieldOnChange.LOW_STOCK}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>

                     <Divider />

                     <Form
                        initialValues={initialValuesBackInStock}
                        form={backInStockForm}
                        onFinish={(val) => handleNotificationChanges(val, "BACK_IN_STOCK")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 Send a <b>back in stock</b> notification to your customers when there are items in their Wishlist that had previously
                                 sold out and are now back in stock.
                              </p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.sendBackInStockNotification}
                                    onChange={(e) =>
                                       handleChangeSwitch(e, "active", "BACK_IN_STOCK", ["minStockLevelSchedule"], backInStockForm, false)
                                    }
                                 />
                                 &nbsp;&nbsp;
                                 <span> Send back in stock notifications</span>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="minStockLevelSchedule"
                                 className="input-control !mb-0"
                                 rules={[
                                    {
                                       required: notificationValues.sendBackInStockNotification,
                                       message: "This field is required!",
                                    },
                                    // { validator: numberValidator },
                                 ]}
                              >
                                 <span className="before:content-['Minimum_stock_level_for_the_back_in_stock_notification_to_be_triggered'] after:content-['(required)']">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={5}
                                       className="!mx-2"
                                       value={notificationValues.minStockLevelToTriggeredForBackInStock}
                                       onChange={(e) => handleChangeField(e, "minStockLevelSchedule", "BACK_IN_STOCK", backInStockForm)}
                                       disabled={!notificationValues.sendBackInStockNotification}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.sendBackInStockNotification}
                                    value={notificationValues.template_id4}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "BACK_IN_STOCK", backInStockForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.BACK_IN_STOCK} onClick={() => handleCancelBtn("BACK_IN_STOCK", backInStockForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => backInStockForm.submit()}
                                 loading={!!notifSaveLoading.BACK_IN_STOCK}
                                 hidden={!fieldOnChange.BACK_IN_STOCK}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>

                     <Divider />

                     <Form
                        initialValues={initialValuesAddItem}
                        form={addItemForm}
                        onFinish={(val) => handleNotificationChanges(val, "ADD_TO_WISHLIST")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>Notify a customer when they add an item to their Wishlist.</p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.enableAddItemNotification}
                                    onChange={(e) => handleChangeSwitch(e, "active", "ADD_TO_WISHLIST", ["configurable_delay"], addItemForm, false)}
                                 />
                                 &nbsp;&nbsp;
                                 <span>Enable Add Item notifications</span>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="configurable_delay"
                                 className="input-control !mb-0"
                                 rules={[
                                    {
                                       required: notificationValues.enableAddItemNotification,
                                       message: "This field is required!",
                                    },
                                    {
                                       validator: (rule, value, callback) => {
                                          return numberValidator(
                                             {
                                                ...rule,
                                                minValue: 5,
                                             },
                                             value,
                                             callback
                                          );
                                       },
                                    },
                                 ]}
                              >
                                 <span className="before:content-['Wait'] after:content-['minutes_before_sending_the_add_item_notification_(required)']">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={0}
                                       className="!mx-2"
                                       value={notificationValues.waitTimeForAddItemNotification}
                                       onChange={(e) => handleChangeField(e, "configurable_delay", "ADD_TO_WISHLIST", addItemForm)}
                                       disabled={!notificationValues.enableAddItemNotification}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.enableAddItemNotification}
                                    value={notificationValues.template_id5}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "ADD_TO_WISHLIST", addItemForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.ADD_TO_WISHLIST} onClick={() => handleCancelBtn("ADD_TO_WISHLIST", addItemForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => addItemForm.submit()}
                                 loading={!!notifSaveLoading.ADD_TO_WISHLIST}
                                 hidden={!fieldOnChange.ADD_TO_WISHLIST}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>

                     <Divider />

                     <Form
                        initialValues={initialValuesStoreVisit}
                        form={storeVisitForm}
                        onFinish={(val) => handleNotificationChanges(val, "POST_STORE_VISIT")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 Send a notification to a customer after they visit one of your stores and have added something to their wishlist.
                              </p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.enablePostStoreVisitNotification}
                                    onChange={(e) =>
                                       handleChangeSwitch(e, "active", "POST_STORE_VISIT", ["configurable_delay"], storeVisitForm, false)
                                    }
                                 />
                                 &nbsp;&nbsp;
                                 <span>Enable Post-Store Visit notifications</span>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="configurable_delay"
                                 className="input-control !mb-0"
                                 rules={[
                                    {
                                       required: notificationValues.enablePostStoreVisitNotification,
                                       message: "This field is required!",
                                    },
                                    {
                                       validator: (rule, value, callback) => {
                                          return numberValidator(
                                             {
                                                ...rule,
                                                minValue: 5,
                                             },
                                             value,
                                             callback
                                          );
                                       },
                                    },
                                 ]}
                              >
                                 <span className="before:content-['Wait'] after:content-['minutes_before_sending_the_store_visit_notification_(required)']">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={0}
                                       className="!mx-2"
                                       value={notificationValues.waitTimeForStoreVisitNotification}
                                       onChange={(e) => handleChangeField(e, "configurable_delay", "POST_STORE_VISIT", storeVisitForm)}
                                       disabled={!notificationValues.enablePostStoreVisitNotification}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.enablePostStoreVisitNotification}
                                    value={notificationValues.template_id6}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "POST_STORE_VISIT", storeVisitForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.POST_STORE_VISIT} onClick={() => handleCancelBtn("POST_STORE_VISIT", storeVisitForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => storeVisitForm.submit()}
                                 loading={!!notifSaveLoading.POST_STORE_VISIT}
                                 hidden={!fieldOnChange.POST_STORE_VISIT}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>

                     <Divider />

                     <Form
                        initialValues={initialValuesNotifyMe}
                        form={notifyMeForm}
                        onFinish={(val) => handleNotificationChanges(val, "NOTIFY_ME")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 <b>Subscription alerts</b>
                              </p>
                           </Col>
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 The following subscriptions provide a fast way for customers to receive an alert about an out-of-stock item, or a
                                 pre-release item without having to sign up to your database. Instead, they can provide just their email address,
                                 and, optionally their mobile phone number.
                              </p>
                           </Col>
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 <b>Notify me</b> will automatically alert subscribers when a currently out of stock item is received back in stock.
                              </p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.sendNotifyMe}
                                    onChange={(e) => handleChangeSwitch(e, "active", "NOTIFY_ME", ["minStockLevelSchedule"], notifyMeForm, false)}
                                 />
                                 &nbsp;&nbsp;
                                 <span>Enable Notify Me subscriptions</span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 name="configurable_delay"
                                 className="input-control !mb-0 "
                                 rules={[
                                    {
                                       required: notificationValues.sendNotifyMe,
                                       message: "This field is required!",
                                    },
                                    {
                                       validator: (rule, value, callback) => {
                                          return numberValidator(
                                             {
                                                ...rule,
                                                minValue: 5,
                                             },
                                             value,
                                             callback
                                          );
                                       },
                                    },
                                 ]}
                              >
                                 <span className="before:content-['Delay_period']  after:content-['(minutes)'] gap-2">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={0}
                                       className="!mx-2"
                                       disabled={!notificationValues.sendNotifyMe}
                                       value={notificationValues.notifyDelayNotification}
                                       onChange={(e) => handleChangeField(e, "configurable_delay", "NOTIFY_ME", notifyMeForm)}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="minStockLevelSchedule"
                                 className="input-control !mb-0"
                                 rules={[
                                    {
                                       required: notificationValues.sendNotifyMe,
                                       message: "This field is required!",
                                    },
                                    { validator: numberValidator },
                                 ]}
                              >
                                 <span className="before:content-['Minimum_stock_level_for_the_back_in_stock_notification_to_be_triggered'] after:content-['(required)']">
                                    <InputNumber
                                       parser={(string: string | undefined) => Math.floor(Number(string)) || null}
                                       min={0}
                                       className="!mx-2"
                                       value={notificationValues.notifyMeMinStockLevel}
                                       onChange={(e) => handleChangeField(e, "minStockLevelSchedule", "NOTIFY_ME", notifyMeForm)}
                                       disabled={!notificationValues.sendNotifyMe}
                                    />
                                 </span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.sendNotifyMe}
                                    value={notificationValues.template_id7}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "NOTIFY_ME", notifyMeForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.NOTIFY_ME} onClick={() => handleCancelBtn("NOTIFY_ME", notifyMeForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => notifyMeForm.submit()}
                                 loading={!!notifSaveLoading.NOTIFY_ME}
                                 hidden={!fieldOnChange.NOTIFY_ME}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>
                     <Form
                        initialValues={initialValuesComingSoon}
                        form={comingSoonForm}
                        onFinish={(val) => handleNotificationChanges(val, "COMING_SOON")}
                        autoComplete="off"
                     >
                        <Row gutter={[16, 16]} className="">
                           <Col span={24}>
                              <p style={{ margin: 0 }}>
                                 <b>Coming soon</b> is typically used create a subscription list of customers that are interested in an upcoming
                                 product range. This is not an automated alert. Instead, you can manually generate a list of subscribers or send a
                                 current list to your marketing platform at any time.
                              </p>
                           </Col>
                           <Col span={24}>
                              <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                                 <Switch
                                    size="small"
                                    className="enable-switch"
                                    checked={notificationValues.sendComingSoon}
                                    onChange={(e) => handleChangeSwitch(e, "active", "COMING_SOON", ["configurable_delay"], comingSoonForm, false)}
                                 />
                                 &nbsp;&nbsp;
                                 <span>Enable Coming Soon subscriptions</span>
                              </Form.Item>
                           </Col>
                           <Col span={24} className="!flex !items-center !gap-2">
                              <Form.Item
                                 label="Template ID"
                                 name="template_id"
                                 className="keys-form-item !mb-1"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input a valid template ID",
                                    },
                                 ]}
                              >
                                 <Input
                                    disabled={!notificationValues.sendComingSoon}
                                    value={notificationValues.template_id8}
                                    onChange={(e) => handleChangeField(e.target.value, "template_id", "COMING_SOON", comingSoonForm)}
                                 />
                              </Form.Item>
                           </Col>
                           <div className="!w-full !flex !justify-end">
                              <Button hidden={!fieldOnChange.COMING_SOON} onClick={() => handleCancelBtn("COMING_SOON", comingSoonForm)}>
                                 Cancel
                              </Button>
                              <Button
                                 type="primary"
                                 className="save-btn !mx-2 "
                                 onClick={() => comingSoonForm.submit()}
                                 loading={!!notifSaveLoading.COMING_SOON}
                                 hidden={!fieldOnChange.COMING_SOON}
                              >
                                 Save Changes
                              </Button>
                           </div>
                        </Row>
                     </Form>
                  </Spin>
               </div>
            </div>
         </div>
         <Divider />
         {/*
      <div className="btn-root !flex sm:!justify-end !items-center gap-1 my-2">
        <span className="font-medium text-xl text-gray-600">
          Enable Klaviyo integration
        </span>
        &nbsp;&nbsp; &nbsp;&nbsp;
        {status.includes(currentStatus.enabled) && (
          <CheckCircleOutlined className="!text-green-600 !text-2xl" />
        )}
        &nbsp;&nbsp;
        <Button
          className="save-btn sm:!w-[15%] my-2"
          onClick={handleEnableBtn}
          disabled={
            status.includes(currentStatus.enabled) ||
            !status.includes(currentStatus.savedKeys)
          }
          loading={!!enableBtnLoading}
        >
          ENABLE
        </Button>
      </div> */}
      </div>
   );
}

export default Dotdigital;
