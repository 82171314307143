export enum FontSizeSystem {
    xxSmall = "6px",
    xSmall = "8px",
    small = "10px",
    medium = "12px",
    large = "14px",
    xLarge = "16px",
    xxLarge = "24px",
    outStanding = "54px",
}

export enum ColorSystem {
    primary = "#59C3B8",
    danger = "#AD1616",
    secondary = "#707070",
    text = "#4D4C4C",
    border = "#D6D6D6",
    background = "#FFFFFF",
    backward = "#E4E4E4",
    disablePaging = "#9D9b9b",
}