import Icon from "@ant-design/icons";
import { IconContainer } from "./icon.styles";

const Configuration = () => {
    return (
        <IconContainer>
            <span role="img">
                <svg
                    data-name="App Configurations"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.138"
                    height="20"
                >
                    <g data-name="Group 13">
                        <path
                            data-name="Path 12"
                            d="M19.124 2.205H10.36a3.221 3.221 0 00-6.113 0H1.012a1.013 1.013 0 000 2.027h3.235a3.217 3.217 0 006.113 0h8.764a1.013 1.013 0 000-2.027zM7.304 4.418a1.2 1.2 0 111.192-1.2 1.2 1.2 0 01-1.192 1.2z"
                            fill="#707070"
                        />
                    </g>
                    <g data-name="Group 14">
                        <path
                            data-name="Path 13"
                            d="M19.124 8.99H16.4a3.215 3.215 0 00-6.1 0H1.017a1.013 1.013 0 000 2.027H10.3a3.215 3.215 0 006.1 0h2.724a1.013 1.013 0 000-2.027zm-5.772 2.205a1.192 1.192 0 111.192-1.192 1.2 1.2 0 01-1.192 1.193z"
                            fill="#707070"
                        />
                    </g>
                    <g data-name="Group 15">
                        <path
                            data-name="Path 14"
                            d="M19.124 15.768h-8.772a3.209 3.209 0 00-6.1 0H1.009a1.013 1.013 0 000 2.027h3.243a3.209 3.209 0 006.1 0h8.772a1.013 1.013 0 000-2.027zm-11.82 2.205a1.192 1.192 0 111.192-1.192 1.194 1.194 0 01-1.192 1.192z"
                            fill="#707070"
                        />
                    </g>
                </svg>
            </span>
        </IconContainer>
    );
};

export const ConfigurationIcon = (props: any) => {
    return <Icon component={Configuration} {...props} />;
};
