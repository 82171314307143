import { Dispatch } from "react";
import { sessionShopHash, sessionToken } from "../../../authServices/jwt";
import { capitalizingSentence } from "../../helpers";
import { IActionType } from "../../interface/commonInterface";
import {
  authFinished,
  confirmResetPasswordCodeFailed,
  confirmResetPasswordCodePending,
  confirmResetPasswordCodeSuccess,
  getAuthFailed,
  getAuthLogOut,
  getAuthPending,
  getAuthSuccess,
  getLoginPending,
  getLogoutFailed,
  getLogoutPending,
  getLogoutSuccess,
  getRegisterFailed,
  getRegisterPending,
  getRegisterSuccess,
  getResetPasswordCodeFailed,
  getResetPasswordCodePending,
  getResetPasswordCodeSuccess,
  getResetTokenFailed,
  getResetTokenPending,
  getResetTokenSuccess,
  refreshTokenFailed,
  refreshTokenPending,
  refreshTokenSuccess,
  resetPasswordFailed,
  resetPasswordPending,
  resetPasswordSuccess,
} from "../action/authAction/authAction";

import {
  confirmResetPasswordCodeEffect,
  getAuthEffect,
  getLoginEffect,
  getLogoutEffect,
  getRegisterEffect,
  getResetPasswordCodeEffect,
  getResetTokenEffect,
  refreshTokenEffect,
  resetPasswordEffect,
} from "../effect/authEffect";

import { LocalStorageKeys } from "../../../authServices/jwt/libs/LocalStorage.enum";
import { getShopHash } from "../../../components/Form/SignInForm/helpers/getShopHash";

import {
  ILoginEffect,
  IRegisterEffect,
  IResetPasswordEffect,
  IResetTokenEffect,
} from "../effect/interface";
import { GetAuthStateContext } from "../../context/authContext";
import { userManagementService } from "../../../api/services/userManagement/usere-management.service";
import { message } from "antd";

export const getAuth = async (dispatch: Dispatch<IActionType>) => {
  dispatch(getAuthPending());

  const { authState } = GetAuthStateContext();

  const shopHash = sessionShopHash.getShopHash() || "";
  try {
    const res: any = await getAuthEffect(shopHash);
    const notification: string =
      capitalizingSentence(res?.data?.message) || "Get Info Successfully";

    if (!res || res.status < 200 || res.status > 300) {
      dispatch(getAuthFailed(notification));
      return { result: false, message: notification };
    }

    const { data } = res.data;
    const { selected_tenant } = data || {};

    let accessToken;

    if (selected_tenant?.tenant_access_token && selected_tenant?.shop_hash) {
      localStorage.setItem(
        "dashboard-token",
        `${selected_tenant.tenant_access_token}`
      );
      localStorage.setItem("dashboard-user", selected_tenant.shop_hash);
    } else {
      const tenant = getShopHash(res.data);

      const authEffectRefetch = await getAuthEffect(tenant);

      accessToken =
        authEffectRefetch?.data?.data.selected_tenant.tenant_access_token;

      localStorage.setItem(LocalStorageKeys.SHOP_HASH, tenant);
      localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, accessToken);
    }

    const tenant =
      shopHash || selected_tenant?.shop_hash || getShopHash(res.data);

    dispatch(
      getAuthSuccess(
        {
          ...data,
          tenant,
          status: authState.auth?.status,
          accessToken,
        } || {}
      )
    );
    return { result: true, message: notification, data };
  } catch (error) {
    const errorMessage = "Cannot Get Authentication";
    dispatch(getAuthFailed(errorMessage));
    return { result: false, message: errorMessage };
  }
};

export const getLogin = async (
  dispatch: Dispatch<IActionType>,
  data: ILoginEffect
) => {
  dispatch(getLoginPending());
  const res: any = await getLoginEffect(data);
  const notification = capitalizingSentence(res?.data?.message);
  if (res && res.status >= 200 && res.status <= 300) {
    // getDashboardToken()   // temp: for get token API
    const token = res.data.data.token;
    sessionToken.setToken(token);
    // dispatch(getLoginSuccess(token || ""));
    return {
      result: true,
      message: notification || "Login Successful",
    };
  }

  dispatch(authFinished());
  return {
    result: false,
    message: notification || "Something Went Wrong",
  };
};

export const getResetToken = async (
  dispatch: Dispatch<IActionType>,
  data: IResetTokenEffect
) => {
  dispatch(getResetTokenPending());
  const res: any = await getResetTokenEffect(data);
  const notification = capitalizingSentence(res?.data?.message);

  if (res && res.status >= 200 && res.status <= 300) {
    const resetToken = res?.data?.data;
    dispatch(getResetTokenSuccess(resetToken || ""));
    return {
      result: true,
      message: notification || "Get Reset Token Successfully",
    };
  }
  dispatch(getResetTokenFailed(notification || "Something went wrong"));
  return {
    result: false,
    message: notification || "Cannot Get Reset Token",
  };
};

export const resetPassword = async (
  dispatch: Dispatch<IActionType>,
  data: IResetPasswordEffect
) => {
  dispatch(resetPasswordPending());
  const res: any = await resetPasswordEffect(data);
  const notification = capitalizingSentence(res?.data?.message);

  if (res && res.status >= 200 && res.status <= 300) {
    dispatch(resetPasswordSuccess());
    return {
      result: true,
      message: notification || "Update Password Successfully",
    };
  }
  dispatch(resetPasswordFailed(notification || "Something went wrong"));
  return {
    result: false,
    message: notification || "Cannot Reset Password",
  };
};

export const getLogout = async (dispatch: Dispatch<IActionType>) => {
  dispatch(getLogoutPending());
  dispatch(getAuthLogOut());
  const res: any = await getLogoutEffect();
  const notification = capitalizingSentence(res?.data?.message);

  if (res && res.status >= 200 && res.status <= 300) {
    sessionToken.clearToken();
    sessionShopHash.clearShopHash();
    localStorage.clear();
    dispatch(getLogoutSuccess());
    return {
      result: true,
      message: notification || "Sign Out Successfully",
    };
  }
  dispatch(getLogoutFailed(notification || "Something went wrong"));
  return {
    result: false,
    message: notification || "Sign Out Failed",
  };
};

export const refreshToken = async (dispatch: Dispatch<IActionType>) => {
  dispatch(refreshTokenPending());
  const res: any = await refreshTokenEffect();
  const notification: string = capitalizingSentence(res?.data?.message);
  if (res && res.status >= 200 && res.status <= 300) {
    const token = res.data?.data;
    sessionToken.setToken(token);
    dispatch(refreshTokenSuccess(token));
    return {
      result: true,
      message: notification || "Refresh Token",
    };
  }
  sessionToken.clearToken();
  dispatch(refreshTokenFailed(notification || "Something went wrong"));
  return {
    result: false,
    message: notification || "Something Went Wrong",
  };
};

export const getResetPasswordCode = async (
  dispatch: Dispatch<IActionType>,
  email: string
) => {
  dispatch(getResetPasswordCodePending());
  const res: any = await getResetPasswordCodeEffect(email);
  const notification: string = capitalizingSentence(res?.data?.message);
  if (res && res.status >= 200 && res.status <= 300) {
    dispatch(getResetPasswordCodeSuccess());
    return {
      result: true,
      message: notification || "Please check your email",
    };
  }
  dispatch(getResetPasswordCodeFailed(notification || "Email Does Not Exists"));
  return {
    result: false,
    message: notification || "Email Does Not Exists",
  };
};

export const confirmResetPasswordCode = async (
  dispatch: Dispatch<IActionType>,
  token: string
) => {
  dispatch(confirmResetPasswordCodePending());
  const res: any = await confirmResetPasswordCodeEffect(token);
  const notification: string = capitalizingSentence(res?.data?.message);
  if (res && res.status >= 200 && res.status <= 300 && res.data?.data) {
    dispatch(confirmResetPasswordCodeSuccess(token));
    return {
      result: true,
      message: notification || "",
    };
  }
  dispatch(confirmResetPasswordCodeFailed(notification || "Wrong token"));
  return {
    result: false,
    message: notification || "Wrong token",
  };
};

export const getRegister = async (
  dispatch: Dispatch<IActionType>,
  data: IRegisterEffect
) => {
  dispatch(getRegisterPending());
  const res: any = await getRegisterEffect(data);
  const notification = capitalizingSentence(res?.data?.message); 
  const registerResponse: any = await userManagementService.registerUser(res.data.data); 

  if (res && res.status >= 200 && res.status <= 300 && registerResponse && registerResponse.code >= 200 && registerResponse.code <= 300) {
    dispatch(getRegisterSuccess());
    return {
      result: true,
      message: notification || "Sign Up Successfully",
    };
  }
  dispatch(getRegisterFailed(notification || "Cannot Create User"));
  return {
    result: false,
    message: notification || "Cannot Create User",
  };
};
