import Icon from "@ant-design/icons";
import { IconContainer } from "./icon.styles";

const Dashboard = () => {
    return (
        <IconContainer>
            <span role="img">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.934"
                    height="20"
                >
                    <g data-name="Group 1">
                        <path
                            data-name="Path 1"
                            d="M7.113 15.202v2.8H1.999v-2.8h5.114m0-2H1.999a2 2 0 00-2 2v2.8a2 2 0 002 2h5.114a2 2 0 002-2v-2.8a2 2 0 00-2-2z"
                            fill="#707070"
                        />
                    </g>
                    <g data-name="Group 2">
                        <path
                            data-name="Path 2"
                            d="M7.21 1.999v7.964H1.999V1.999H7.21m0-2H1.999a2 2 0 00-2 2v7.964a2 2 0 002 2H7.21a2 2 0 002-2V1.999a2 2 0 00-2-2z"
                            fill="#707070"
                        />
                    </g>
                    <g data-name="Group 3">
                        <path
                            data-name="Path 3"
                            d="M17.935 1.999v2.8h-5.227v-2.8h5.227m0-2h-5.227a2 2 0 00-2 2v2.8a2 2 0 002 2h5.227a2 2 0 002-2v-2.8a2 2 0 00-2-2z"
                            fill="#707070"
                        />
                    </g>
                    <g data-name="Group 4">
                        <path
                            data-name="Path 4"
                            d="M17.935 10.037v7.965H12.61v-7.965h5.325m0-2H12.61a2 2 0 00-2 2v7.965a2 2 0 002 2h5.325a2 2 0 002-2v-7.965a2 2 0 00-2-2z"
                            fill="#707070"
                        />
                    </g>
                </svg>
            </span>
        </IconContainer>
    );
};

export const DashboardIcon = (props: any) => {
    return <Icon component={Dashboard} {...props} />;
};
