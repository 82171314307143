import {
  GetAuthPending,
  GetAuthSuccess,
  GetAuthFailed,
  GetLoginPending,
  GetLoginFailed,
  GetLoginSuccess,
  ResetPasswordPending,
  ResetPasswordSuccess,
  ResetPasswordFailed,
  GetLogoutPending,
  GetLogoutSuccess,
  GetLogoutFailed,
  RefreshTokenPending,
  RefreshTokenSuccess,
  RefreshTokenFailed,
  GetResetPasswordCodePending,
  GetResetPasswordCodeSuccess,
  GetResetPasswordCodeFailed,
  ConfirmResetPasswordCodePending,
  ConfirmResetPasswordCodeSuccess,
  ConfirmResetPasswordCodeFailed,
  GetRegisterPending,
  GetRegisterSuccess,
  GetRegisterFailed,
  GetAuthLogOut,
  GetAuthFinish,
} from "../action/authAction/authActionType";
import { IStateInitialAuth } from "../../interface/stateInitial";
import { IActionType } from "../../interface/commonInterface";
import { User } from "../../interface/model/user";

export const initAuthState: IStateInitialAuth = {
  pending: false,
  refreshToken: "",
  accessToken: "",
  auth: null as User | null,
  errorMsg: "",
  resetPasswordToken: "",
  logOutClick: false,
};

export function authReducer(
  state = initAuthState,
  action: IActionType
): IStateInitialAuth {
  const { type, payload } = action;
  switch (type) {
    // Authentication
    case GetAuthLogOut:
      return {
        ...state,
        logOutClick: true,
      };

    case GetAuthPending:
      return {
        ...state,
        pending: true,
      };
    case GetAuthFailed:
      return {
        ...state,
        pending: false,
        errorMsg: payload,
      };
    case GetAuthSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
        auth: payload,
        accessToken: payload.accessToken,
      };
    case GetLoginPending:
      return {
        ...state,
        pending: true,
      };
    case GetLoginSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
        accessToken: payload,
      };
    case GetLoginFailed:
      return {
        ...state,
        pending: false,
        errorMsg: payload,
      };
    case GetRegisterPending:
      return {
        ...state,
        pending: true,
      };
    case GetRegisterSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
      };
    case GetRegisterFailed:
      return {
        ...state,
        pending: false,
        errorMsg: payload,
      };
    case GetLogoutPending:
      return {
        ...state,
        pending: true,
      };
    case GetLogoutSuccess:
      return {
        pending: false,
        errorMsg: "",
        refreshToken: "",
        accessToken: "",
        resetPasswordToken: "",
        auth: {} as User,
        logOutClick: false,
      };
    case GetLogoutFailed:
      return {
        ...state,
        pending: false,
        errorMsg: payload,
      };
    case ResetPasswordPending:
      return {
        ...state,
        pending: true,
      };
    case ResetPasswordSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
      };
    case GetAuthFinish:
      return {
        ...state,
        pending: false,
      };
    case ResetPasswordFailed:
      return {
        ...state,
        pending: false,
        errorMsg: payload,
      };
    case RefreshTokenPending:
      return state;
    case RefreshTokenSuccess:
      return {
        ...state,
        errorMsg: "",
        accessToken: payload,
      };
    case RefreshTokenFailed:
      return {
        ...state,
        accessToken: "",
        errorMsg: payload,
      };
    case GetResetPasswordCodePending:
      return {
        ...state,
        pending: true,
      };
    case GetResetPasswordCodeSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
      };
    case GetResetPasswordCodeFailed:
      return {
        ...state,
        errorMsg: payload,
        pending: false,
      };

    case ConfirmResetPasswordCodePending:
      return {
        ...state,
        pending: true,
      };
    case ConfirmResetPasswordCodeSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
        resetPasswordToken: payload,
      };
    case ConfirmResetPasswordCodeFailed:
      return {
        ...state,
        pending: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
}
