import { useEffect, useState } from "react";
import {
  JwtService,
  authLocalStorage,
  sessionShopHash,
} from "../../authServices/jwt";
import { User } from "../interface/model/user";
import { GetAuthStateContext } from "../context/authContext";
import { getAuthSuccess } from "../store/action/authAction/authAction";
import { Roles } from "../../configs/rolePemisions/roles.enum";

export const useKeycloakUser = () => {
  const { authState, dispatch } = GetAuthStateContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getKeyloackUser = async () => {
      const tenant = authLocalStorage.getShopHash();

      try {
        setLoading(true);
        const userData = await authLocalStorage.getKeycloakUser();
        const accessToken = authLocalStorage.getToken();

        if (!userData) return;

        const twcRoles = JwtService.getUserRole(
          userData.realm_access.roles
        ) as Roles[];

        const payload = {
          email: userData.email,
          id: userData?.sub,
          status: twcRoles,
          user_name: userData.name,
        } as User;

        if (twcRoles.includes(Roles.TwcSuperUser) || twcRoles.includes(Roles.TwcGroupOwner)) {
          sessionShopHash.setShopHash(tenant as string);
          payload.tenant = tenant as string;
        } else {
          sessionShopHash.setShopHash(userData.tenantid);
          payload.tenant = userData.tenantid;
        }

        if (accessToken) {
          payload.accessToken = accessToken;
        }

        dispatch(getAuthSuccess(payload));
      } catch {
        console.error("Error getting user data");
      } finally {
        setLoading(false);
      }
    };

    getKeyloackUser();
  }, []);

  useEffect(() => {
    if (!authState.auth) return;

    setLoading(false);
  }, [authState.auth]);

  return loading;
};
