import { lazy } from "react";

const SelectTenantId = lazy(() => import("../../../components/SelectTenant"));
const PendingLayout = lazy(() => import("../../../layouts/Pending"));

function SelectTenant() {
   return (
      <>
         <PendingLayout>
            <SelectTenantId />
         </PendingLayout>
      </>
   );
}

export default SelectTenant;
