import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, message, Row, Col } from "antd";

import { SignInContainer } from "./sign-in.styles";
import {
  getAuth,
  getLogin,
} from "../../../utility/store/middlerware/authMiddleware";
import { GetAuthStateContext } from "../../../utility/context/authContext";
import Loading from "../../UI/Loading";
import { layout, layoutApp } from "../../../configs/layout/authLayout";
import {
  authLocalStorage,
  sessionShopHash,
  sessionToken,
} from "../../../authServices/jwt";
import { getShopById } from "../../../utility/store/middlerware/shopMiddleware";
import { GetShopStateContext } from "../../../utility/context/shopContext";
import { getKlaviyoIntegration } from "../../../utility/store/middlerware/mainMiddleware";
import { authFinished } from "../../../utility/store/action/authAction/authAction";

function getShopHash(res: any) {
  if (
    !res?.data?.shops ||
    !Array.isArray(res.data.shops) ||
    res.data.shops.length === 0
  ) {
    throw new Error("No shops found in API response");
  }

  const firstShop = res.data.shops[0];

  if (!firstShop || typeof firstShop.shop_hash === "undefined") {
    throw new Error("Invalid shop data in API response");
  }

  return firstShop.shop_hash;
}

const SignInForm = ({ onClose }: { onClose: () => void }) => {
  const history = useHistory();
  const { dispatch, authState } = GetAuthStateContext();

  const { singleDispatch, integrationDispatch } = GetShopStateContext();
  const onFinish = async (values: { username: string; password: string }) => {
    const { username, password } = values;
    const _sessionShopHash = sessionShopHash.getShopHash() || "";
    getLogin(dispatch, {
      username,
      password,
      shop_hash: _sessionShopHash,
    })
      .then((res: any) => { 
        if (res.result) { 
          if (!_sessionShopHash) {
            const getInfo = async () => {
              await getAuth(dispatch)
                .then(async (res: any) => {
                  if (!res.result) {
                    message.error(res.message);
                    return false;
                  } else {
                    if (res?.data?.shops?.length > 1) {
                      history.push("/select-shop");
                      return;
                    }

                    const hash = getShopHash(res);

                    if (!hash) {
                      throw new Error("Shop hash is empty");
                    }
                    sessionShopHash.setShopHash(hash);
                    await getAuth(dispatch);
                    getShopById(singleDispatch, hash).then((res: any) => {
                      if (res?.result) {
                        getKlaviyoIntegration(integrationDispatch).then(
                          (res: any) => {
                            // Klaviyo inte–gration for hide notification menu and routing
                            history.push("/");
                          }
                        );
                      } else {
                        message.info(
                          "Cannot get shop information. Please try to login again"
                        );
                        setTimeout(() => {
                          window.location.reload();
                          localStorage.clear();
                          sessionToken.clearToken();
                        }, 1000);
                      }
                    });

                    return true;
                  }
                })
                .catch((e) => {
                  console.log(e);
                  message.info(
                    "Cannot get shop information. Please try to login again"
                  );
                  setTimeout(() => {
                    window.location.reload();
                    localStorage.clear();
                    sessionToken.clearToken();
                  }, 1000);
                });
            };

            getInfo();
          } else {
            authLocalStorage.setSessionAppInside(_sessionShopHash);

            getAuth(dispatch).then((res: any) => res);
            getShopById(singleDispatch, _sessionShopHash).then((res: any) =>
              history.push("/app-configurations/launch-point")
            );
          }
          message.success(res.message);
          onClose();
        } else if (!res.result) {
          message.error(res.message);
        }
      })
      .catch((e: any) => {
        console.log("Error while trying to login", e);
        message.info(
          "Error occured while loading the page. Please try to login again"
        );
        history.push("/auth/sign-in");
      })
      .finally(() => {
        dispatch(authFinished());
      });
  };

  const validateMessages = {
    // eslint-disable-next-line
    required: "${label} is required!",
    types: {
      // eslint-disable-next-line
      email: "${label} is not a valid email!",
    },
  };

  const onFinishFailed = (errorInfo: any): void => {};

  return (
    <SignInContainer className="btn-custom link-custom form-custom">
      <h1 className="form-custom-title">Login</h1>
      <Form
        validateMessages={validateMessages}
        {...layoutApp}
        layout="horizontal"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Email"
          labelAlign="right"
          name="username"
          rules={[
            {
              required: true,
            },
            // {
            //     validator: (_, value) => {
            //         if (!value) {
            //             return Promise.resolve()
            //         }
            //         const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            //         const result = emailRegex.test(value);
            //         console.log(value)
            //         console.log(result)
            //         if (result) {
            //             return Promise.resolve()
            //         } else {
            //             return Promise.reject(new Error('Invalid email'))
            //         }
            //     }
            // }
          ]}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24}>
              <Input size="middle" type="text" />
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          label="Password"
          labelAlign="right"
          name="password"
          rules={[
            {
              required: true,
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }
                // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
                // const result = passwordRegex.test(value);

                if (value.length >= 5) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error("Password must contain at least 5 characters")
                  );
                }
              },
            },
          ]}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24}>
              <Input.Password size="middle" />
            </Col>
          </Row>
        </Form.Item>

        <Form.Item className="mb-0 footer">
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24}>
              <div className="btn--container">
                <Button type="primary" className="button" htmlType="submit">
                  Log in
                </Button>
              </div>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      {authState.pending && <Loading />}
    </SignInContainer>
  );
};

export default React.memo(SignInForm);
