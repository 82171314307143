import React, { createContext, useReducer } from "react";

import {
  initIntegration,
  initShopListState,
  initShopSingleState,
  initShopTimeState,
  integrateReducer,
  shopListReducer,
  shopSingleReducer,
  shopTimeReducer,
} from "../store/reducer/shopReducer";
import { IShopContext } from "./interface";

const ShopStateContext = createContext<IShopContext>({
  shopTimeState: initShopTimeState,
  shopListState: initShopListState,
  shopSingleState: initShopSingleState,
  integrationResponse: initIntegration,
  singleDispatch: () => null,
  listDispatch: () => null,
  timeDispatch: () => null,
  integrationDispatch: () => null,
});

export function GetShopStateContext(): IShopContext {
  const context = React.useContext(ShopStateContext);
  if (context === undefined) {
    throw new Error("ShopStateContext not found");
  }
  return context;
}

// Provider
export const ShopStateProvider: React.FC = ({ children }) => {
  const [shopSingleState, singleDispatch] = useReducer(
    shopSingleReducer,
    initShopSingleState
  );
  const [shopListState, listDispatch] = useReducer(
    shopListReducer,
    initShopListState
  );
  const [shopTimeState, timeDispatch] = useReducer(
    shopTimeReducer,
    initShopTimeState
  );
  const [integrationResponse, integrationDispatch] = useReducer(
    integrateReducer,
    initIntegration
  );

  return (
    <ShopStateContext.Provider
      value={{
        shopSingleState,
        singleDispatch,
        shopListState,
        listDispatch,
        shopTimeState,
        timeDispatch,
        integrationResponse,
        integrationDispatch,
      }}
    >
      {children}
    </ShopStateContext.Provider>
  );
};
