import styled from "styled-components";

export const AppCss = styled.div`
    // overflow: hidden;
    @media (min-width: 576.1px) {
        .display-mb {
            display: none !important;
        }
    }
    @media (max-width: 576px) {
        .display-dk {
            display: none !important;
        }
    }

    font-family: SFPro;
    .ant-radio.ant-radio-checked {
        .ant-radio-inner {
            border-color: #59c3b8;
            &:after {
                background-color: #59c3b8;
                top: 50%;
                left: 50%;
                width: 12px;
                height: 12px;
                transform: scale(1) translate(-50%, -50%);
                margin: 0;
            }
        }
    }
    .ant-switch {
        .ant-switch-handle {
            &:before {
                box-shadow: 0 2px 0px 0 rgb(0 35 11 / 20%);
            }
        }
    }
    .ant-switch.ant-switch-checked {
        background-color: #59c3b8;
    }

    .btn-cancel-footer {
        background-color: #707070;
        border-color: #707070;
        color: white;
        margin: 0 19px 0 33px;
    }

    .btn-custom {
        .ant-btn.ant-btn-primary {
            background-color: #59c3b8;
            border-color: #59c3b8;
        }
        .ant-btn.ant-btn-primary.ant-btn-dangerous {
            background-color: #ad1616;
            border-color: #ad1616;
        }
    }

    .link-custom {
        a {
            color: #59c3b8;
        }
    }

    .form-custom {
        .ant-form-item-label
            > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            color: black;
        }
    }

    .menu-custom {
        .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
            display: none;
        }
        .ant-menu-item.ant-menu-item-selected {
            background-color: white;
            color: #59c3b8;
            font-weight: bold;
        }
        .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected {
            color: #59c3b8;
            font-weight: bold;
            .ant-menu-item {
                font-weight: normal;
            }
            .ant-menu-item.ant-menu-item-selected {
                /* font-weight: bold; */
                color: black;
                :before {
                    content: "";
                    width: 5px;
                    height: 15px;
                    background-color: #59c3b8;
                    border-radius: 7px;
                    position: absolute;
                    top: 14px;
                    left: 40px;
                }
            }
        }
        .ant-menu-vertical .ant-menu-item,
        .ant-menu-vertical-left .ant-menu-item,
        .ant-menu-vertical-right .ant-menu-item,
        .ant-menu-submenu.ant-menu-submenu-inline,
        .ant-menu-submenu-title,
        .ant-menu-inline .ant-menu-item {
            :after {
                border: none;
            }
            :hover {
                color: #59c3b8;
            }
        }
    }
`;
