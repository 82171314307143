import { lazy } from "react";
import { Redirect } from "react-router-dom";

import { sessionShopHash, sessionToken } from "../../authServices/jwt";
import AntSuspense from "../../components/UI/AntSuspense";
import Auth from "../../views/pages/Auth";
import SelectShop from "../../views/pages/SelectShop";
import { ConfigEmailStateProvider } from "../context/configEmailContext";
import { EmailStateProvider } from "../context/emailContext";

import { CategoryKeys } from "../../configs/data/category.enum";
import NotFound from "../../views/pages/NotFound";
import { GetAuthStateContext } from "../context/authContext";

import { GetShopStateContext } from "../context/shopContext";
import SelectTenant from "../../views/pages/SelectTenant";

const Plan = lazy(() => import("../../views/pages/Plan"));
const QuoteNotification = lazy(() => import("../../components/Notification"));

const ConfigEmailNotification = lazy(
  () => import("../../components/Configuration/ConfigNotifications")
);

export const useRouteManagement = (
  checkPageAccess: (
    Component: JSX.Element,
    pathKey: CategoryKeys
  ) => JSX.Element
) => {
  // const _sessionShopHash = sessionShopHash.getShopHash();
  const _sessionToken = sessionToken.getToken();
  const { authState } = GetAuthStateContext();
  const { shopSingleState } = GetShopStateContext();

  function AuthComponent() {
    return (
      <AntSuspense>
        <Auth />
      </AntSuspense>
    );
  }

  function SelectShopComponent() {
    // if (!_sessionToken && !authState.accessToken) {
    //   return <Redirect to="/auth" />;
    // }

    // if (_sessionShopHash) {
    //   return <Redirect to="/" />;
    // }

    return (
      <AntSuspense>
        <SelectShop />
      </AntSuspense>
    );
  }

  function SelectTenantComponent() {
    return (
      <AntSuspense>
        <SelectTenant />
      </AntSuspense>
    );
  }

  function PlansComponent() {
    if (!_sessionToken && !authState.accessToken) {
      return <Redirect to="/auth" />;
    }

    if (
      shopSingleState.detail?.shop_attributes?.plan?.is_default ||
      !shopSingleState.detail?.shop_attributes?.subscription_is_active
    ) {
      return (
        <AntSuspense>
          <Plan />
        </AntSuspense>
      );
    }

    return <Redirect to="/" />;
  }

  function QuoteNotificationComponent() {
    return checkPageAccess(
      <ConfigEmailStateProvider>
        <EmailStateProvider>
          <AntSuspense>
            <QuoteNotification />
          </AntSuspense>
        </EmailStateProvider>
      </ConfigEmailStateProvider>,
      CategoryKeys.NOTIFICATIONS
    );
  }

  function ConfigEmailNotificationComponent() {
    return checkPageAccess(
      <ConfigEmailStateProvider>
        <EmailStateProvider>
          <AntSuspense>
            <ConfigEmailNotification />
          </AntSuspense>
        </EmailStateProvider>
      </ConfigEmailStateProvider>,
      CategoryKeys.NOTIFICATIONS
    );
  }

  function NotFoundComponent() {
    if (!_sessionToken && !authState.accessToken) {
      return (
        <AntSuspense>
          <Auth />
        </AntSuspense>
      );
    }
    return (
      <AntSuspense>
        <NotFound />
      </AntSuspense>
    );
  }

  return {
    AuthComponent,
    SelectShopComponent,
    PlansComponent,
    QuoteNotificationComponent,
    ConfigEmailNotificationComponent,
    NotFoundComponent,
    SelectTenantComponent
  };
};
