import Icon from "@ant-design/icons";
import { IconContainer } from "./icon.styles";

const Reporting = () => {
    return (
        <IconContainer>
            <span role="img">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.831"
                    height="20"
                >
                    <g data-name="Group 19">
                        <g data-name="Group 18">
                            <g data-name="Group 17">
                                <path
                                    data-name="Path 15"
                                    d="M1.003 20a1 1 0 01-1-1V2.503a2.5 2.5 0 012.489-2.5h14.853a2.466 2.466 0 011.763.736 2.5 2.5 0 01.726 1.767v11.995a2.494 2.494 0 01-.725 1.767 2.467 2.467 0 01-1.764.737H4.395l-2.676 2.7a1 1 0 01-.716.295zM2.488 2.007a.489.489 0 00-.482.5v14.047l1.254-1.266a1 1 0 01.713-.3h13.368a.473.473 0 00.338-.143.5.5 0 00.144-.354V2.503a.5.5 0 00-.145-.355.473.473 0 00-.337-.141z"
                                    fill="#707070"
                                />
                            </g>
                        </g>
                    </g>
                    <g data-name="Group 20" fill="#707070">
                        <path
                            data-name="Path 16"
                            d="M4.113 8.581h3.01v5.027h-3.01z"
                        />
                        <path
                            data-name="Path 17"
                            d="M12.73 5.754h3.01v7.853h-3.01z"
                        />
                        <path
                            data-name="Path 18"
                            d="M8.421 3.554h3.01v10.054h-3.01z"
                        />
                    </g>
                </svg>
            </span>
        </IconContainer>
    );
};

export const ReportingIcon = (props: any) => {
    return <Icon component={Reporting} {...props} />;
};
