enum CategoryKeys {
  DASHBOARD = "dashboard",
  SUBSCRIPTION = "subscription",
  INTEGRATIONS = "integrations",
  APP_CONFIGURATIONS = "app-configurations",
  NOTIFICATIONS = "notifications",
  USER_ADMINISTRATION = "user-administration",
  REPORTING = "reporting",
  POS_CONFIG = "pos-config",
  DOCUMENTATION = "documentation",
  LOGOUT = "logout",
}

export { CategoryKeys };
