import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { rgba } from "../../components/interface/appInterface";
import { DeviceType } from "../../constant/variables";

const capitalizing = (str: string): string => {
    if (!str) {
        return str;
    }
    return str
        .split("-")
        .map((element) =>
            element[0].toUpperCase().concat(element.slice(1).toLowerCase())
        )
        .join(" ");
};

const capitalizingSentence = (str: string): string => {
    if (!str) {
        return str;
    }
    return str
        .split(" ")
        .map((element) =>
            element[0].toUpperCase().concat(element.slice(1).toLowerCase())
        )
        .join(" ");
};

const lowercase = (str: string): string => {
    return str
        .split(" ")
        .map((element) => element.toLowerCase())
        .join("-");
};

const showDeleteConfirm = (content: string, metaData: any, callback: any) => {
    Modal.confirm({
        title: "Are you sure delete row?",
        icon: <ExclamationCircleOutlined />,
        content: content,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: () => callback(true, metaData),
        onCancel: () => callback(false, metaData),
    });
};

const toCss = (color: rgba | undefined): string => {
    if (!color) return "";
    return `rgba(${color?.r},${color?.g},${color?.b},${color?.a})`;
};

const toDate = (num: number | undefined): string | null => {
    if (!num) {
        return null;
    }
    const date = new Date(num * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${day >= 10 ? day : `0${day}`}/${
        month >= 10 ? month : `0${month}`
    }/${year}`;
};

const toDateString = (num: number | undefined): string => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    if (!num) {
        return "";
    }
    const date = new Date(num * 1000);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day >= 10 ? day : `0${day}`} ${month}, ${year}`;
};

const downloadCsv = (link: string) => {
    fetch(link, {
        method: "GET",
    })
        .then((res : any) => res.blob())
        .then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = link.split("/").pop() || "product_wishlist_report.csv";
            a.click();
            a.remove();
        });
};

const toggleSidebarMobile = () => {
    const nav = document.querySelector(".nav-container");
    if (nav) {
        if (nav?.classList.contains("is-active")) {
            nav.classList.remove("is-active");
            document
                .querySelector(".container-sidebar")
                ?.classList.remove("is-active");
        } else {
            nav.classList.add("is-active");
            document
                .querySelector(".container-sidebar")
                ?.classList.add("is-active");
        }
    }
};

const toggleMenuMobile = () => {
    const nav = document.querySelector(".nav-menu--container");
    if (nav) {
        if (nav?.classList.contains("is-active")) {
            nav.classList.remove("is-active");
            document
                .querySelector(".container-menu")
                ?.classList.remove("is-active");
        } else {
            nav.classList.add("is-active");
            document
                .querySelector(".container-menu")
                ?.classList.add("is-active");
        }
    }
};

const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return DeviceType.tablet;
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return DeviceType.mobile;
    }
    return DeviceType.desktop;
};

const isDashboardTokenExist = () => {
    const dashboard_token = localStorage.getItem("dashboard-token");
    const dashboard_user = localStorage.getItem("dashboard-user");

    return !dashboard_token || !dashboard_user;
}

export {
    capitalizing,
    showDeleteConfirm,
    lowercase,
    capitalizingSentence,
    toCss,
    toDate,
    toDateString,
    downloadCsv,
    toggleSidebarMobile,
    toggleMenuMobile,
    getDeviceType,
    isDashboardTokenExist
};
