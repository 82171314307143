import styled from "styled-components";

export const IconContainer = styled.span`
    span {
        /* min-width: 14px; */
        margin-right: 4px;
        /* font-size: 14px; */
        /* height: 20px; */
        /* display: inline-block; */
        display: flex;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
`;
