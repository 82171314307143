import Icon from "@ant-design/icons";
import { IconContainer } from "./icon.styles";

const Link = () => {
    return (
        <IconContainer>
            <span role="img">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.462"
                    height="10"
                    fill="currentColor"
                >
                    <g data-name="Group 64">
                        <g data-name="Group 63">
                            <path
                                data-name="Path 67"
                                d="M1.962 10h5.231a1.961 1.961 0 001.961-1.962V6.73a.654.654 0 00-.654-.654.654.654 0 00-.654.654v1.308a.654.654 0 01-.654.654H1.961a.654.654 0 01-.654-.654V2.807a.654.654 0 01.654-.654h1.308a.654.654 0 00.654-.654.654.654 0 00-.654-.654H1.961A1.961 1.961 0 00-.001 2.806v5.231A1.962 1.962 0 001.962 10z"
                            />
                            <path
                                data-name="Path 68"
                                d="M7.197 5.418a.654.654 0 00.925 0L10.27 3.27a.654.654 0 000-.925L8.117.192a.654.654 0 00-.925 0 .654.654 0 000 .925l1.037 1.037H7.193A3.923 3.923 0 003.27 6.077v.654a.654.654 0 00.654.654.654.654 0 00.654-.654v-.654a2.615 2.615 0 012.616-2.615H8.23L7.199 4.493a.654.654 0 000 .925z"
                            />
                        </g>
                    </g>
                </svg>
            </span>
        </IconContainer>
    );
};

export const LinkIcon = (props: any) => {
    return <Icon component={Link} {...props} />;
};
