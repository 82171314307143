export function getShopHash(res: any) {
  if (
    !res?.data?.shops ||
    !Array.isArray(res.data.shops) ||
    res.data.shops.length === 0
  ) {
    return null;
  }

  const firstShop = res.data.shops[0];

  if (!firstShop || typeof firstShop.shop_hash === "undefined") {
    return null;
  }

  return firstShop.shop_hash;
}
