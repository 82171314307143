import { IShop, IShopTime } from "../../../interface/model/shop";
import {
    CancelShopPlanFailed,
    CancelShopPlanPending,
    CancelShopPlanSuccess,
    GetShopByIdFailed,
    GetShopByIdPending,
    GetShopByIdSuccess,
    GetShopListFailed,
    GetShopListPending,
    GetShopListSuccess,
    GetShopTimeLeftFailed,
    GetShopTimeLeftPending,
    GetShopTimeLeftSuccess,
} from "./shopActionType";
import { IPagination } from "../../../interface/commonInterface";

export function getShopListPending(pagination: IPagination) {
    return {
        type: GetShopListPending,
        payload: pagination,
    };
}

export function getShopListSuccess(
    shops: Array<IShop>,
    total: number | undefined
) {
    return {
        type: GetShopListSuccess,
        payload: { shops, total },
    };
}

export function getShopListFailed(errMsg: string) {
    return {
        type: GetShopListFailed,
        payload: errMsg,
    };
}

// get shop detail
export function getShopByIdPending() {
    return {
        type: GetShopByIdPending,
    };
}

export function getShopByIdSuccess(shop: IShop) {
    return {
        type: GetShopByIdSuccess,
        payload: shop,
    };
}

export function getShopByIdFailed(errMsg: string) {
    return {
        type: GetShopByIdFailed,
        payload: errMsg,
    };
}

export function cancelShopPlanPending() {
    return {
        type: CancelShopPlanPending,
    };
}

export function cancelShopPlanSuccess() {
    return {
        type: CancelShopPlanSuccess,
    };
}

export function cancelShopPlanFailed() {
    return {
        type: CancelShopPlanFailed,
    };
}

export function getShopTimesLeftPending() {
    return {
        type: GetShopTimeLeftPending,
    };
}

export function getShopTimesLeftSuccess(time: IShopTime) {
    return {
        type: GetShopTimeLeftSuccess,
        payload: time,
    };
}

export function getShopTimesLeftFailed(errMsg: string) {
    return {
        type: GetShopTimeLeftFailed,
        payload: errMsg,
    };
}
