import styled from "styled-components";
import { Layout } from "antd";
import { FontSizeSystem } from "../../../../constant/styles";

const toggleSize = 40;
const toggleMargin = 10;
const toggleLine = 4;
const offset = toggleSize * 0.5;
// const toggleColor = "#59c3b8";

export const HeaderContainer = styled(Layout.Header)`
    z-index: 19;
    background: #59c3b8;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    & .logo {
        display: flex;
        align-items: center;
        img {
            height: 36px;
            padding: 5px;
            margin-left: 20px;
            vertical-align: unset;
        }
    }

    & .shop-info {
        line-height: 48px;
        & .button__shop-domain {
            margin: 0 12px;
            font-size: ${FontSizeSystem.medium};
            padding: 0 4px;
            height: unset;
        }
        .ant-select-arrow {
            left: -5px;
            color: white;
            top: 50%;
            font-size: ${FontSizeSystem.medium};
        }
        .ant-select-selection-item {
            padding: 0;
            font-size: ${FontSizeSystem.medium};
        }

        .icon__link {
            margin-right: 72px;
            button {
                padding: 4px;
                height: unset;
                border: 0;
            }
        }
    }

    & .icon {
        display: inline-block;
        font-size: 18px;
        line-height: 48px;
        padding: 0 15px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #1890ff;
        }
    }

    & .notification-icon {
        line-height: 40px;
        padding: 0 5px;
    }

    & .logout-button {
        margin-right: 10px;
    }

    & .nav-menu--container {
        position: relative;
        display: inline-block;
        max-width: ${`${toggleSize + toggleMargin}px`};
        max-height: ${`${toggleSize + toggleMargin}px`};
        overflow: visible;
        outline: none;
        display: none;

        //&:focus-within, &:focus {
        &.is-active {
            .nav-toggle {
                &:after {
                    content: ">";
                }
            }

            .nav-items {
                transform: translate(0, 0);
            }
        }

        .nav-toggle {
            position: relative;
            width: ${`${toggleSize}px`};
            height: ${`${toggleSize}px`};
            z-index: 2;
            margin: 0 10px;

            &:hover {
                cursor: pointer;
            }

            &:after {
                content: "<";
                font-size: 40px;
                position: absolute;
                top: -5px;
                left: 0;
                transform: translate(0, 0);
                width: 100%;
                height: 100%;
                color: #ffffff;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
            }
        }
    }

    .nav-container {
        position: relative;
        display: inline-block;
        max-width: ${`${toggleSize + toggleMargin}px`};
        max-height: ${`${toggleSize + toggleMargin}px`};
        overflow: visible;
        outline: none;
        display: none;

        //&:focus-within, &:focus {
        &.is-active {
            .nav-toggle {
                &:before,
                &:after {
                    box-shadow: none;
                }

                &:before {
                    transform: rotate(-45deg);
                }

                &:after {
                    transform: rotate(45deg);
                }
            }

            .nav-items {
                transform: translate(0, 0);
            }
        }

        .nav-toggle {
            position: relative;
            width: ${`${toggleSize}px`};
            height: ${`${toggleSize}px`};
            z-index: 2;
            margin: 0 10px;

            &:hover {
                cursor: pointer;
            }

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: ${`${offset - toggleLine / 2}px`};
                left: 0;
                transform: translate(0, 0);
                width: 100%;
                height: ${`${toggleLine}px`};
                background: #ffffff;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
            }
            &:before {
                box-shadow: 0 -${`${offset / 1.5}px`} 0 0 #ffffff;
            }

            &:after {
                box-shadow: 0 ${`${offset / 1.5}px`} 0 0 #ffffff;
            }
        }
    }

    @media (max-width: 576px) {
        .nav-container,
        .nav-menu--container {
            display: block;
        }
    }
`;
