import React from "react";

import { SidebarContainer } from "./header.styles";
import { Menu } from "antd";
import { GetShopStateContext } from "../../../../utility/context/shopContext";
import { GetAuthStateContext } from "../../../../utility/context/authContext";
import { IShop } from "../../../../utility/interface/model/shop";
import { sessionShopHash } from "../../../../authServices/jwt";
import { SettingIcon } from "../../../../assets/icon/SettingIcon";
import { LinkIcon } from "../../../../assets/icon/LinkIcon";

import Loading from "../../../../components/UI/Loading";

function HeaderSidebar() {
    const { shopSingleState } = GetShopStateContext();
    const { authState } = GetAuthStateContext();

    const goToShop = () => {
        window.open(`https://${shopSingleState.detail?.domain}`, "_blank");
    };
    const changeShop = (e: any) => {
        if (e.keyPath[1] !== "select_shop") {
            return;
        }
        const shop_hash = e.keyPath[0];
        sessionShopHash.setShopHash(shop_hash);
        window.location.reload();
    };

    return (
        <>
            <SidebarContainer
                className="sidebar menu-custom"
                collapsible
                theme="light"
                width="240px"
            >
                <Menu theme="light" mode="inline" onClick={changeShop}>
                    {authState.auth?.shops && (
                        <Menu.SubMenu key="select_shop" title="Select shop">
                            {authState.auth?.shops?.map((item: IShop) => (
                                <Menu.Item key={item.shop_hash}>
                                    {item.shop_hash}
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    )}
                    <Menu.Item icon={<LinkIcon />} onClick={goToShop}>
                        Shop
                    </Menu.Item>
                    <Menu.Item icon={SettingIcon}>Settings</Menu.Item>
                    <Menu.Item>{authState.auth?.email}</Menu.Item>
                </Menu>
            </SidebarContainer>
            {authState.pending && <Loading />}
        </>
    );
}

export default HeaderSidebar;
