import Icon from "@ant-design/icons";
import { IconContainer } from "./icon.styles";

const Setting = () => {
    return (
        <IconContainer>
            <span role="img">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.578"
                    height="10"
                >
                    <g data-name="Group 41">
                        <g data-name="Group 40">
                            <path
                                data-name="Path 43"
                                d="M5.254 1l-.014.1a1.643 1.643 0 00.834 1.662l.029.016a1.823 1.823 0 00.862.225 1.585 1.585 0 00.993-.342l.08-.064.458.788-.084.034a1.7 1.7 0 00-.006 3.142l.091.037-.458.788-.08-.063-.02-.016a1.621 1.621 0 00-.979-.326 1.724 1.724 0 00-.86.232 1.678 1.678 0 00-.872 1.669l.015.114h-.92l.014-.1a1.649 1.649 0 00-.849-1.67 1.791 1.791 0 00-.872-.232 1.614 1.614 0 00-1 .342l-.08.063-.458-.788.084-.034a1.7 1.7 0 00.007-3.142l-.09-.037.458-.788.08.064.021.016a1.618 1.618 0 00.979.326 1.724 1.724 0 00.86-.232 1.678 1.678 0 00.872-1.669l-.015-.114h.92m.216-1H4.119a.819.819 0 00-.8.929l.042.317a.68.68 0 01-.369.665.738.738 0 01-.371.1.627.627 0 01-.379-.126l-.253-.2a.826.826 0 00-.5-.172.805.805 0 00-.7.4L.113 3.075a.839.839 0 00-.064.676.786.786 0 00.464.486l.285.116a.692.692 0 01.433.654.675.675 0 01-.433.634l-.285.116a.785.785 0 00-.464.486.843.843 0 00.064.676l.676 1.162a.808.808 0 00.549.39.878.878 0 00.155.014.784.784 0 00.5-.172l.253-.2a.6.6 0 01.377-.126.788.788 0 01.383.1.649.649 0 01.349.655l-.042.317a.789.789 0 00.19.644.82.82 0 00.612.285h1.352a.819.819 0 00.8-.929l-.042-.317a.68.68 0 01.37-.665.737.737 0 01.371-.1.628.628 0 01.379.126l.253.2a.806.806 0 00.5.172.841.841 0 00.154-.014.805.805 0 00.549-.39l.674-1.155a.837.837 0 00.064-.676.784.784 0 00-.465-.486l-.285-.116a.693.693 0 01-.433-.654.675.675 0 01.433-.634l.285-.116a.782.782 0 00.464-.486.841.841 0 00-.063-.672l-.676-1.161a.808.808 0 00-.549-.391.884.884 0 00-.155-.014.784.784 0 00-.5.172l-.253.2a.592.592 0 01-.373.126.818.818 0 01-.388-.1.648.648 0 01-.348-.654l.042-.317a.788.788 0 00-.19-.644.822.822 0 00-.612-.285z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g data-name="Group 42">
                        <path
                            data-name="Path 44"
                            d="M4.789 6.684A1.684 1.684 0 116.473 5a1.686 1.686 0 01-1.684 1.684zm0-2.368A.684.684 0 105.473 5a.685.685 0 00-.684-.684z"
                            fill="#fff"
                        />
                    </g>
                </svg>
            </span>
        </IconContainer>
    );
};

export const SettingIcon = (props: any) => {
    return <Icon component={Setting} {...props} />;
};
