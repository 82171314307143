import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AntSuspense from "../../../components/UI/AntSuspense";
import AuthenticationLayout from "../../../layouts/Authentication";
import SignUpForm from "../../../components/Form/SignUpForm";

const SignInForm = lazy(() => import("../../../components/Form/SignInForm"));
const RequestResetPassword = lazy(
  () => import("../../../components/Form/RequestResetPasswordForm")
);
const ResetPasswordForm = lazy(
  () => import("../../../components/Form/ResetPasswordForm")
);
const ConfirmResetPasswordCode = lazy(
  () => import("../../../components/Form/ConfirmResetPasswordCode")
);

function Auth() {
  return (
    <AuthenticationLayout>
      <Switch>
        <Route exact path="/auth/sign-in">
          <AntSuspense>
            <SignInForm />
          </AntSuspense>
        </Route>
        <Route exact path="/auth/sign-up">
                    <AntSuspense>
                        <SignUpForm/>
                    </AntSuspense>
                </Route>
        <Route exact path="/auth/forgot-password">
          <AntSuspense>
            <RequestResetPassword />
          </AntSuspense>
        </Route>
        <Route exact path="/auth/reset-password">
          <AntSuspense>
            <ResetPasswordForm />
          </AntSuspense>
        </Route>
        <Route exact path="/auth/confirm-code">
          <AntSuspense>
            <ConfirmResetPasswordCode />
          </AntSuspense>
        </Route>
        <Redirect to="/auth/sign-in" />
      </Switch>
    </AuthenticationLayout>
  );
}

export default Auth;
