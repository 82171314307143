import {IInitConfigEmailDetailState, IInitConfigEmailUpdateState} from "../../interface/stateInitial";
import {IActionType} from "../../interface/commonInterface";
import {ConfigEmailActionType as config} from "../action/configEmailAction/configEmailActionType";

// get detail config
export const initConfigEmailDetailState: IInitConfigEmailDetailState = {
    pending: false,
    errorMsg: '',
    detail: null
}

export const configEmailDetailReducer = (state = initConfigEmailDetailState, action: IActionType): IInitConfigEmailDetailState => {
    const {type, payload} = action
    switch (type) {
        case config.GetEmailConfigPending:
            return {
                ...state,
                errorMsg: '',
                pending: true
            }
        case config.GetEmailConfigSuccess:
            return {
                ...state,
                errorMsg: '',
                detail: payload,
                pending: false
            }
        case config.GetEmailConfigFailed:
            return {
                ...state,
                errorMsg: payload,
                pending: false,
                detail: null
            }
        default:
            return {...state}
    }
}


export const initConfigEmailUpdateState: IInitConfigEmailUpdateState = {
    pending: false,
    errorMsg: '',
    configId: -1
}

//edit email
export const configEmailUpdateReducer = (state = initConfigEmailUpdateState, action: IActionType): IInitConfigEmailUpdateState => {
    const {type, payload} = action
    switch (type) {
        // edit email
        case config.UpdateEmailConfigPending:
            return {
                ...state,
                pending: true,
                errorMsg: ''
            }
        case config.UpdateEmailConfigFailed:
            return {
                ...state,
                pending: false,
                errorMsg: payload,
                configId: -1
            }
        case config.UpdateEmailConfigSuccess:
            return {
                ...state,
                errorMsg: '',
                pending: false,
                configId: payload
            }
        default:
            return {...state}
    }
}
