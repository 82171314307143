import { IActionType } from "../../interface/commonInterface";
import {
  IInitShopListState,
  IInitShopSingleState,
  IInitShopTimeState,
  integrationTypes,
} from "../../interface/stateInitial";
import {
  CancelShopPlanFailed,
  CancelShopPlanPending,
  CancelShopPlanSuccess,
  Failed,
  GetShopByIdFailed,
  GetShopByIdPending,
  GetShopByIdSuccess,
  GetShopListFailed,
  GetShopListPending,
  GetShopListSuccess,
  GetShopTimeLeftFailed,
  GetShopTimeLeftPending,
  GetShopTimeLeftSuccess,
  Init,
  Success,
} from "../action/shopAction/shopActionType";

//get Shops

export const initShopListState: IInitShopListState = {
  pending: false,
  errorMsg: "",
  result: [],
  pagination: {
    current: 1,
    defaultPageSize: 10,
    pageSize: 10,
    total: 0,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
  },
};

//get Shops
export function shopListReducer(
  state = initShopListState,
  action: IActionType
): IInitShopListState {
  const { type, payload } = action;
  switch (type) {
    //get list Shop
    case GetShopListPending:
      return {
        ...state,
        pending: true,
        pagination: payload || state.pagination,
      };
    case GetShopListFailed:
      return {
        ...state,
        pending: false,
        errorMsg: payload,
      };
    case GetShopListSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
        result: payload?.Shops,
        pagination: {
          ...state.pagination,
          total: payload.total,
        },
      };
    default:
      return { ...state };
  }
}

//get single Shop
export const initShopSingleState: IInitShopSingleState = {
  pending: false,
  errorMsg: "",
  detail: null,
};

export const shopSingleReducer = (
  state = initShopSingleState,
  action: IActionType
): IInitShopSingleState => {
  const { type, payload } = action;
  switch (type) {
    // find one Shop
    case GetShopByIdPending:
      return {
        ...state,
        errorMsg: "",
        pending: true,
      };
    case GetShopByIdSuccess:
      return {
        ...state,
        errorMsg: "",
        detail: payload,
        pending: false,
      };
    case GetShopByIdFailed:
      return {
        ...state,
        errorMsg: payload,
        pending: false,
        detail: null,
      };
    case CancelShopPlanPending:
      return {
        ...state,
        pending: true,
      };
    case CancelShopPlanSuccess:
      return {
        ...state,
        errorMsg: "",
        pending: false,
      };
    case CancelShopPlanFailed:
      return {
        ...state,
        errorMsg: payload,
        pending: false,
        detail: null,
      };

    default:
      return { ...state };
  }
};

//get single Shop
export const initShopTimeState: IInitShopTimeState = {
  pending: false,
  errorMsg: "",
  time: null,
};

export const shopTimeReducer = (
  state = initShopTimeState,
  action: IActionType
): IInitShopTimeState => {
  const { type, payload } = action;
  switch (type) {
    // find one Shop
    case GetShopTimeLeftPending:
      return {
        ...state,
        errorMsg: "",
        pending: true,
      };
    case GetShopTimeLeftSuccess:
      return {
        ...state,
        errorMsg: "",
        time: payload,
        pending: false,
      };
    case GetShopTimeLeftFailed:
      return {
        ...state,
        errorMsg: payload,
        pending: false,
        time: null,
      };
    default:
      return { ...state };
  }
};

// 3rd party integration
export const initIntegration: integrationTypes = {
  activate: false,
  loading: false,
  error: "",
};

export const integrateReducer = (
  state = initIntegration,
  action: IActionType
): integrationTypes => {
  const { type, payload } = action;
  switch (type) {
    case Init:
      return { ...state, loading: true, error: "" };
    case Success:
      return { ...state, loading: false, activate: payload };
    case Failed:
      return { ...state, loading: false, error: payload };
    default:
      return { ...state };
  }
};
