import numeral from "numeral";
import { useEffect, useRef, useState } from "react";

export const DateBeforeDays = (days: number) => {
  var date = new Date();
  var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  var dateString = new Date(last.getTime() - last.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
  return dateString;
};

export const color = [
  "#8884d8",
  "#82ca9d",
  "#bc5090",
  "#ff9aac",
  "#58508d",
  "#ea5545",
  "#c86558",
  "#bdcf32",
  "#0040ff",
  "#5e569b",
];
export function convertAbbreviation(str: string): number {
  let num = parseFloat(str);
  let lastChar = str[str.length - 1];

  switch (lastChar) {
    case "k":
      num *= 1000;
      break;
    case "m":
      num *= 1000000;
      break;
    case "b":
      num *= 1000000000;
      break;
    case "t":
      num *= 1000000000000;
      break;
  }

  return num;
}

export const changeIndexInArray = (
  dataArray: Array<any>,
  newIndex: number,
  key: string,
  valueToMove: Boolean | string | number
) => {
  const index = dataArray?.findIndex((item: any) => item[key] === valueToMove);
  if (index !== -1) {
    const newArray = [...dataArray];
    const [removedItem] = newArray.splice(index, 1);
    newArray.splice(newIndex, 0, removedItem);
    return newArray;
  } else return dataArray;
};

export const toCronExpression = (
  interval: number,
  unit: "days" | "min" | null
): string => {
  if (unit === "days") {
    return `0 0 0 */${interval} * *`;
  } else if (unit === "min") {
    return `* */${interval} * * * *`;
  } else {
    throw new Error('Invalid unit. Only "days" and "minutes" are supported.');
  }
};

export const cronToTime = (expression: string, type: "days" | "min") => {
  if (type === "days" && expression) {
    const regex = /(\d+) \d+ \*\/(\d+) \* \*/;
    const match = expression?.match(regex);
    return match ? parseInt(match[2], 10) : null;
  } else if (type === "min" && expression) {
    const parts = expression?.split(" ");
    const subExpression = parts[1]?.split("/");
    const number = subExpression[1];
    return parseInt(number, 10);
  }
};

export const AmountFormat = (value: string | number) => {
  if (value?.toString() !== "" && value?.toString() !== undefined) {
    const formatedValue = numeral(Number(value)).format(
      "0,0.00[0000000000000000000000000000000000000000]"
    );
    const digits1 = formatedValue?.split(".")[0];
    const digits2 = formatedValue?.split(".")[1]?.slice(0, 2);
    if (digits2 && digits2 !== "00") return String(digits1 + "." + digits2);
    else return String(digits1);
  } else return "";
};

export const AmountFormatWithNoDecimal = (value: number | string) => {
  const numericValue = Number(
    typeof value === "string" ? parseFloat(value).toFixed() : value?.toFixed()
  );

  if (!isNaN(numericValue)) {
    return numeral(numericValue.toFixed()).format("0");
  } else {
    return "";
  }
};

export const AmountRoundWith1Decimal = (value: string | number) => {
  if (value?.toString() !== "" && value?.toString() !== undefined) {
    const amount = parseFloat(value.toString()).toFixed(1);
    const digits1 = amount?.split(".")[0];
    const digits2 = amount?.split(".")[1]?.slice(0, 1);
    if (digits2 && digits2 !== "0") return String(digits1 + "." + digits2);
    else return String(digits1);
  } else return "";
};

export const StringToDate = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleString();
  return formattedDate;
};

export function adjustColor(color: string, amount: number) {
  let R = parseInt(color.substr(1, 2), 16);
  let G = parseInt(color.substr(3, 2), 16);
  let B = parseInt(color.substr(5, 2), 16);

  R = R + amount;
  G = G + amount;
  B = B + amount;

  if (R > 255) R = 255;
  else if (R < 0) R = 0;

  if (G > 255) G = 255;
  else if (G < 0) G = 0;

  if (B > 255) B = 255;
  else if (B < 0) B = 0;

  let RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export const useApi = (
  integration: (params?: any) => Promise<any>,
  params?: URLSearchParams
) => {
  const [resData, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);
        setLoading(true);

        const response = await integration(params);
        if (response?.data) setData(response?.data);
        // else setError("Failed to fetch data");
        setLoading(false);
      } catch (err: any) {
        const errorResponse = err?.response?.data;
        setError(
          errorResponse?.error || errorResponse?.title || errorResponse?.message
        );
        setLoading(false);
      }
    };
    fetchData();
  }, [integration, refresh]);

  const reload = () => setRefresh(!refresh);
  return { resData, loading, error, reload };
};
