import { CategoryKeys } from "../../configs/data/category.enum";
import { RolePermissions } from "../../configs/rolePemisions";
import { Roles } from "../../configs/rolePemisions/roles.enum";

export const checkUserPagePermission = (
  roles: Roles[],
  pages: CategoryKeys
) => {
  return roles.some((role) =>
    Boolean(
      (RolePermissions as Record<Roles, CategoryKeys[]>)[role]?.includes(pages)
    )
  );
};
